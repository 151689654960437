import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

/** Function to check if the subdomain is 'chat' */
const isChatSubdomain = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  return subdomain === "chat";
};

export function NoIndexProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        {!isChatSubdomain() && <meta name="robots" content="noindex" />}
      </Helmet>
      {children}
    </>
  );
}
