import { createRope } from "~/redux/utils/createRope";
import { api } from "~/redux/api.generated";
import { addBidirectionalLink } from "~/redux/utils/ropeUtils";

export const AccountUserRope = createRope("Account_User", {
  extraReducers: (builder) =>
    builder.addMatcher(api.endpoints.whoAmI.matchFulfilled, (state, action) => {
      const userId = action.payload.me.id;
      action.payload.me.owned_accounts.forEach((account) => {
        // Use the utility function for adding links
        addBidirectionalLink(state, userId, account.id);
      });
    }),
});
