/** @overview - Manages Root Route component of the app. */
import { useLocation, useRoutes } from "react-router-dom";

import { Suspense, cloneElement, useRef } from "react";
import { AnimatePresence } from "framer-motion";

/**
 * @overview - Manages Root Routes object of the app.
 *
 * Q: Why use an object? Isn't it cleaner to use JSX routes?
 * A: No, it is cleaner to use the routes object because it makes hyperlinks
 * within the app type safe using the useAppNavigate function.
 */
import React from "react";
import type { DeepWritable } from "ts-essentials";
import { AllRoutePaths } from "~/types/AllRoutePaths";
import { PaymentPage } from "../pages/PaymentPage";
import { Demo } from "../pages/Demo/Demo";

const Page404 = React.lazy(() =>
  import("../pages/Page404").then((module) => ({ default: module.Page404 })),
);
const AuthPage = React.lazy(() =>
  import("../pages/AuthPage/AuthPage").then((module) => ({
    default: module.AuthPage,
  })),
);
const OAuth = React.lazy(() =>
  import("../pages/OAuth").then((module) => ({ default: module.OAuth })),
);
const ChatPage = React.lazy(() =>
  import("../pages/ChatPage/ChatPage").then((module) => ({
    default: module.ChatPage,
  })),
);

/**
 * Routes are created using the object-based routes api.
 *
 * See more
 *  - https://reacttraining.com/blog/react-router-v6-pre/#object-based-routes
 *  - https://reactrouter.com/en/main/hooks/use-routes
 */
const routes = (() => {
  const readonlyRoutes = [
    {
      path: "/",
      children: [
        { path: "", element: <ChatPage /> },
        // url/c/:slug
        // url/c/:slug?from=chat
        // url/c/:slug?from=chat&messageId=123
        {
          path: "c/",
          children: [
            { path: ":slug", element: <ChatPage /> },
            { path: ":slug/*", element: <ChatPage /> },
          ],
        },
        {
          path: "/upgrade",
          element: <PaymentPage />,
        },

        // OAUTH
        { path: "auth", element: <AuthPage /> }, // page users go to sign in/up
        { path: "oauth", element: <OAuth /> }, // page backend redirects to

        { path: "demo", element: <Demo /> },

        { path: "*", element: <Page404 /> },
      ],
    },
  ] as const;
  const routes = readonlyRoutes as DeepWritable<typeof readonlyRoutes>;
  return routes;
})();

/**
 * @todo - Test that this type matches the allowed urls programatically.
 */
export type AllowedRouteUrls = AllRoutePaths<typeof routes>;

export type AllowedQueryParams = `?${
  /** Example Query Param */
  `from=${AllowedRouteUrls}`
}`;

/** Example */
// import { AssertTrue, Extends } from "../../testing/typescriptTestUtils";
// type SignInPath = "/sign-in";

// export type tests = [
//
// AssertTrue<Extends<SignInPath, AllowedRouteUrls>>
// ];

/**
 * App Routes component for the app.
 *
 * @see routes.tsx - Routes are located here.
 * @important - DO NOT PUT INITIALIZATION LOGIC IN HERE. EITHER EACH PAGE INITIALIZES ITSELF
 * OR GLOBAL INITIALIZATION SHOULD OCCUR IN THE `AppProvider` COMPONENT.
 * @returns The app routes, the whole app routes, and nothing but the app routes.
 */
export const AppRoutes = (): JSX.Element => {
  const location = useLocation();

  const ref = useRef<HTMLElement>(null);

  const routesToCompute = useRoutes(routes, location);
  if (routesToCompute === null) return <Suspense>{<Page404 />}</Suspense>;
  const computedRoutes = cloneElement(routesToCompute, {
    key: location.pathname,
    ref,
  });

  return (
    <Suspense>
      <AnimatePresence mode="wait">{computedRoutes}</AnimatePresence>
    </Suspense>
  );
};
