import React, { useEffect, useState } from "react";
import { ProgressBox } from "../steps/ProgressBox";
import { Step } from "../steps/ProgressSteps";
import { createNamedContext } from "~/utils/createNamedContext";
import {
  useCreateIntentToPurchaseCreditsMutation,
  useGetCreditProductsQuery,
} from "~/redux/api.generated";
import { useAppSelector } from "~/redux/store";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { cn } from "~/utils/cn";
import _ from "lodash";
import {
  EnterStripePaymentDetails,
  EnterStripePaymentDetailsProvider,
} from "./EnterStripePaymentDetails";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { env } from "~/env";
import confetti from "canvas-confetti";
import { LoadableLogo } from "../LoadableLogo";

// Define the state and the updater type using createNamedContext
const { useStep, StepProvider } = createNamedContext({
  name: "step",
  initialState: {
    currentStepIndex: 0,
    productName: null as string | null, // e.g. "1000 Credits"
    productPriceInDollars: null as string | null, // e.g. "$10.00"
    clientSecret: null as string | null,
  },
});

const steps: Step[] = [
  {
    id: "01",
    name: "Credits",
    description: "Choose how many credits you need.",
  },
  {
    id: "02",
    name: "Payment Details",
    description: "Enter your payment details.",
  },
  {
    id: "03",
    name: "Confirmation",
    description: "Confirm your order.",
  },
];

function StepContainer({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <div
      data-debugname="step-container"
      className={cn("mx-auto h-full max-w-3xl p-4 px-6", className)}
    >
      {children}
    </div>
  );
}

const stepComponents = [
  function StepOneCreditsContent(): JSX.Element {
    const [, setState] = useStep();
    ////////////////////////////////////////////////////////////////////////////
    const [createIntentToPurchaseCredits, resultCreateIntentToPurchaseCredits] =
      useCreateIntentToPurchaseCreditsMutation();

    const resultGetCreditProducts = useGetCreditProductsQuery();

    const creditProducts =
      resultGetCreditProducts.data?.getCreditProducts ?? [];
    console.log("creditProducts", creditProducts);

    const user = useAppSelector((state) => state.auth.user);
    const userId = user?.id!;

    const accountIds = useAppSelector(
      (state) => state.rope_Account_User[userId],
    );
    const accountId = accountIds?.[0];

    const [selectedProductId, setSelectedProductId] = useState<string | null>(
      null,
    );

    const [productIdToClientSecret, setProductIdToClientSecret] = useState<
      Record<string, string | null>
    >({});
    useEffect(() => {
      if (_.isNil(selectedProductId)) return;

      if (selectedProductId in productIdToClientSecret) {
        return;
      } else {
        setProductIdToClientSecret((prev) => ({
          ...prev,
          [selectedProductId]: null,
        }));
      }

      console.log("selectedProductId", selectedProductId);
      createIntentToPurchaseCredits({
        accountId,
        creditProductId: selectedProductId,
      }).then((result) => {
        if ("error" in result) return;

        const clientSecret = ((result as any).data
          ?.createIntentToPurchaseCredits?.client_secret ?? null)!;

        setProductIdToClientSecret((prev) => ({
          ...prev,
          [selectedProductId]: clientSecret,
        }));
        const targetProduct = creditProducts.find(
          _.matches({ id: selectedProductId }),
        );
        const productName = targetProduct?.name ?? null;
        const productPriceInDollars =
          targetProduct?.regular_price_formatted ?? null;
        setState((prev) => ({
          ...prev,
          clientSecret,
          productName,
          productPriceInDollars,
        }));
      });
    }, [selectedProductId]);
    ////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
      if (selectedProductId === null) return;
      const timeout = setTimeout(() => {
        setState((prev) => ({
          ...prev,
          currentStepIndex: 1,
        }));
      }, 400);
      return () => clearTimeout(timeout);
    }, [selectedProductId]);

    return (
      <StepContainer>
        {creditProducts.length !== 0 && (
          <div>
            <label className="text-lg font-medium text-gray-900 dark:text-gray-100">
              Choose Your Credits
            </label>

            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              {creditProducts
                ?.map((deliveryMethod, i) => {
                  if (deliveryMethod === null) return null;
                  console.log("deliveryMethod", deliveryMethod);
                  console.log("i", i);
                  const checked = selectedProductId === deliveryMethod.id;
                  const active = selectedProductId === deliveryMethod.id;
                  return (
                    <button
                      key={`radio-${deliveryMethod.id}`}
                      value={deliveryMethod.id}
                      className={cn(
                        "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none dark:bg-slate-800",
                        {
                          "border-transparent": checked,
                          "border-gray-300 dark:border-gray-700": !checked,
                          "ring-2 ring-primary-500": active,
                        },
                      )}
                      onClick={() => {
                        setSelectedProductId(deliveryMethod.id);
                      }}
                    >
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <label className="block text-sm font-medium text-gray-900 dark:text-gray-100">
                              {deliveryMethod?.name}
                            </label>
                            <span className="mt-1 flex items-center text-sm text-gray-500">
                              {deliveryMethod?.credit_amount}
                            </span>
                            <span className="mt-6 text-sm font-medium text-gray-900 dark:text-gray-100">
                              {deliveryMethod?.regular_price_formatted}
                            </span>
                          </span>
                        </span>
                        {checked ? (
                          <CheckCircleIcon
                            className="h-5 w-5 text-primary-600"
                            aria-hidden="true"
                          />
                        ) : null}
                        <span
                          className={cn(
                            active ? "border" : "border-2",
                            checked
                              ? "border-primary-500"
                              : "border-transparent",
                            "pointer-events-none absolute -inset-px rounded-lg",
                          )}
                          aria-hidden="true"
                        />
                      </>
                    </button>
                  );
                })
                .filter(_.matches(null))}
            </div>
          </div>
        )}
      </StepContainer>
    );
  },
  function StepTwoContent(): JSX.Element {
    const [{ clientSecret }, setState] = useStep();

    const nextStep = () => {
      setState((prev) => ({
        ...prev,
        currentStepIndex: 2,
      }));
    };

    if (clientSecret) return <StepTwoWithClientSecret />;
    return <></>;
  },
  function StepThreeContent(): JSX.Element {
    const [{ clientSecret }] = useStep();

    if (clientSecret) return <StepThreeWithClientSecret />;
    return <></>;
  },
];

function StepTwoWithClientSecret() {
  const elements = useElements();
  const [{ clientSecret }, setState] = useStep();

  const [errorMessage, setErrorMessage] = useState();

  const handleError = (error: any) => {
    setErrorMessage(error.message);
  };

  const [isValidated, setIsValidated] = useState(false);

  console.log("elements", elements);

  const validateButDontSubmit = async () => {
    console.log("stripe:validateButDontSubmit[0]");
    const result = await elements!.submit();
    const { error: submitError } = result;
    console.log("stripe:validateButDontSubmit[1]");
    console.log(JSON.stringify(result, null, 2));
    if (submitError) {
      console.log("stripe:validateButDontSubmit[2]");
      handleError(submitError);
      console.log("stripe:validateButDontSubmit[3]");
      return;
    } else {
      setIsValidated(true);
      setState((prev) => ({
        ...prev,
        currentStepIndex: 2,
      }));
    }
  };

  const nextStep = () => {
    setState((prev) => ({
      ...prev,
      currentStepIndex: 2,
    }));
  };

  return (
    <StepContainer className="flex flex-col justify-between">
      {clientSecret && (
        <EnterStripePaymentDetails clientSecret={clientSecret} />
      )}
      {errorMessage && (
        <div className="mt-4">
          <div className="text-red-500">{errorMessage}</div>
        </div>
      )}
      <button
        onClick={!isValidated ? validateButDontSubmit : nextStep}
        className="float-right mt-4 rounded-md bg-primary-500 px-4 py-2 text-white"
      >
        Next
      </button>
    </StepContainer>
  );
}

function StepThreeWithClientSecret() {
  const stripe = useStripe();
  const elements = useElements();
  const [{ clientSecret, productName, productPriceInDollars }] = useStep();
  const [errorMessage, setErrorMessage] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const handleError = (error: any) => {
    setErrorMessage(error.message);
  };

  const confirmOrder = async () => {
    if (clientSecret === null) return;
    if (!stripe) {
      console.log("stripe:handleSubmit:!stripe");
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Confirm the PaymentIntent using the details collected by the Payment Element
    console.log("I AM ABOUT TO CONFIRM THE PAYMENT HERE I GO");
    setIsLoading(true);
    const resultOfConfirmPayment = await stripe.confirmPayment({
      elements: elements!,
      clientSecret: clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: env.VITE_STRIPE_RETURN_URL,
      },
    });
    const { error } = resultOfConfirmPayment;
    console.log("resultOfConfirmPayment", resultOfConfirmPayment);

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
      // The payment has been processed!
      // Handle the result here.
      console.log("stripe:confirmOrder:success");
      setIsComplete(true);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <StepContainer>
        <div className="flex w-full flex-col items-center justify-center">
          <LoadableLogo isAnimating />
          <h1 className="my-2 text-sm font-medium text-primary-600">
            Placing Order...
          </h1>
        </div>
      </StepContainer>
    );
  }
  if (isComplete) {
    return <OrderComplete />;
  }
  return (
    <StepContainer>
      <div className="flex h-full w-full flex-col justify-between rounded-3xl p-8 ring-1 ring-primary-600 dark:bg-slate-900">
        <div className="flex items-center justify-between gap-x-4">
          <h2 className="rounded-full bg-primary-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
            AI Credit Refill
          </h2>
          <p className="text-xs font-semibold leading-8 dark:text-gray-100">
            one-time purchase
          </p>
        </div>
        <table className="my-8 w-full text-gray-700 dark:text-gray-200">
          <thead>
            <tr>
              <th className="text-left font-bold">Description</th>
              <th className="text-right font-bold">Amount</th>
            </tr>
          </thead>
          <tbody className="gap-y-96">
            <tr>
              <td className="text-left">{productName}</td>
              <td className="text-right">{productPriceInDollars}</td>
            </tr>
          </tbody>
          <tfoot className="text-primary-600 dark:text-primary-400">
            <tr>
              <td className="text-left font-bold">Total</td>
              <td className="text-right font-bold">{productPriceInDollars}</td>
            </tr>
          </tfoot>
        </table>
        <button
          onClick={confirmOrder}
          className="mt-6 block w-full rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        >
          Place your order
        </button>
      </div>
    </StepContainer>
  );
}

function OrderComplete() {
  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
      disableForReducedMotion: true,
    });
  }, []);

  return (
    <StepContainer>
      <h1 className="text-sm font-medium text-primary-600">
        Payment successful
      </h1>
      <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-gray-100">
        Thanks for ordering
      </p>
      <p className="mt-2 text-base text-gray-500 dark:text-gray-300">
        We appreciate your order! We're processing it now & your credits will be
        added to your account shortly.
      </p>
    </StepContainer>
  );
}

function ProgressBoxContainer() {
  const [{ currentStepIndex, clientSecret }, setState] = useStep();

  const onStepChange = (dx: number) => {
    setState((prev) => ({ ...prev, currentStepIndex: dx }));
  };

  return (
    <EnterStripePaymentDetailsProvider clientSecret={clientSecret}>
      <ProgressBox
        stepDx={currentStepIndex}
        steps={steps}
        stepComponents={stepComponents}
        onStepChange={onStepChange}
      />
    </EnterStripePaymentDetailsProvider>
  );
}

export function PaymentSteps({
  onClickCancel,
}: {
  onClickCancel: any;
}): JSX.Element {
  void onClickCancel;
  return (
    <StepProvider>
      <ProgressBoxContainer />
    </StepProvider>
  );
}
