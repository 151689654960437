/** @overview - Redux slice for managing non-cached chat view state. */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useParams } from "react-router";
import { useAppSelector } from "../store";
import { DraftedMessage } from "./messageListSlice";

/** The initial state for the non-cached chat view slice. */
const initialState = {
  /** Indicates whether the chat view is in the middle. */
  isMiddle: <boolean>true,
  /** The ID of the primary drafted message, or null if none. */
  primaryDraftedMessageId: <null | string>null,
  /** The ID of the primary drafted conversation */
  primaryDraftedConversationId: <null | string>null,
  /** Most recently sent drafted messages. */
  mostRecentlySentDraft: <DraftedMessage | null>null,
  /** Specifies whether the mobile sidebar is open */
  isMobileSidebarOpen: <boolean>false,
};

/** Redux slice for managing non-cached chat view state. */
export const nonCachedChatViewSlice = createSlice({
  name: "nonCachedChatView",
  initialState,
  reducers: {
    /** Sets whether the chat view is in the middle. */
    setIsMiddle(state, action: PayloadAction<boolean>) {
      state.isMiddle = action.payload;
    },
    /** Focuses on a drafted message by its ID. */
    setPrimaryDraftedMessageId(state, action: PayloadAction<string | null>) {
      state.primaryDraftedMessageId = action.payload;
    },
    /** Sets the primary drafted conversation ID. */
    setPrimaryDraftedConversationId(state, action: PayloadAction<string>) {
      state.primaryDraftedConversationId = action.payload;
    },
    /** Sets the most recently sent drafted message. */
    setMostRecentlySentDraft(state, action: PayloadAction<DraftedMessage>) {
      state.mostRecentlySentDraft = action.payload;
    },
    /** Sets whether the mobile sidebar is open */
    setIsMobileSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isMobileSidebarOpen = action.payload;
    },
  },
});

/** Exports actions from the nonCachedChatViewSlice slice. */
export const {
  setIsMiddle,
  setPrimaryDraftedMessageId,
  setPrimaryDraftedConversationId,
  setMostRecentlySentDraft,
  setIsMobileSidebarOpen,
} = nonCachedChatViewSlice.actions;

export function useIsMiddle() {
  const { slug = null } = useParams<{ slug?: string }>();
  return (
    useAppSelector((state) => state.nonCachedChatView.isMiddle) && slug === null
  );
}
