/** @overview - Manages chat messages bucket. */

import { AppSchema, type AppChatMessage } from "~/redux/apiTypes";
import { createBucket } from "~/redux/utils/createBucket";
import { api } from "~/redux/api.generated";
import _ from "lodash";
import { PayloadAction } from "@reduxjs/toolkit";
import { WEBSOCKET__CHAT_MESSAGE_UPDATED } from "~/utils/WebSocketSingleton/WebSocketConstants";

/**
 * Updates or adds a chat message to the Redux state bucket.
 * @param state - The current state of the Redux store.
 * @param message - The chat message data to be updated or added.
 */
function updateChatMessageBucket(state: any, message: any) {
  const {
    child_messages,
    continuation_of_message_parent,
    continuing_message_child,
    credit_cost,
    favorite_child,
    model_definition,
    parent_message,
    resent_message_parent,
    resent_message_children,
    ...messageData
  } = message;

  if (state[message.id] === undefined) {
    state[message.id] = {
      status: "fulfilled",
      error: null,
      meta: {
        child_messages,
        continuation_of_message_parent,
        continuing_message_child,
        credit_cost,
        favorite_child,
        model_definition,
        parent_message,
        resent_message_parent,
        resent_message_children,
      },
      lastUpdated: Date.now(),
      data: AppSchema.ChatMessage.parse(messageData),
    };
  } else {
    state[message.id] = {
      ...state[message.id],
      meta: {
        ...state[message.id].meta,
        child_messages,
        continuation_of_message_parent,
        continuing_message_child,
        credit_cost,
        favorite_child,
        model_definition,
        parent_message,
        resent_message_parent,
        resent_message_children,
      },
      lastUpdated: Date.now(),
      data: AppSchema.ChatMessage.parse(messageData),
    };
  }
}

export const ChatMessagesBucket = createBucket<
  AppChatMessage,
  "ChatMessage",
  {
    child_messages: string | null | undefined;
    continuation_of_message_parent: string | null | undefined;
    continuing_message_child: string | null | undefined;
    credit_cost: string | null | undefined;
    favorite_child: string | null | undefined;
    model_definition: string | null | undefined;
    parent_message: string | null | undefined;
    resent_message_parent: string | null | undefined;
    resent_message_children: string | null | undefined;
  }
>("ChatMessage", {
  extraReducers: (builder) =>
    builder
      .addMatcher(
        api.endpoints.getGoldenPathMessages.matchFulfilled,
        (state, action) => {
          const goldenPathMessages =
            action.payload.getConversation.golden_path_messages.data;

          goldenPathMessages.forEach((goldenPathMessage: any) => {
            updateChatMessageBucket(state, goldenPathMessage);
          });
        },
      )
      .addMatcher(
        api.endpoints.createChatCompletionTextMessage.matchFulfilled,
        (state, action) => {
          const chatMessage = action.payload.createChatCompletionTextMessage;
          updateChatMessageBucket(state, chatMessage);
        },
      )
      .addMatcher(
        api.endpoints.createNewChatCompletionTextMessage.matchFulfilled,
        (state, action) => {
          const chatMessage = action.payload.createChatCompletionTextMessage;
          updateChatMessageBucket(state, chatMessage);
        },
      )
      .addMatcher(
        _.matches({ type: WEBSOCKET__CHAT_MESSAGE_UPDATED }),
        (
          state,
          action: PayloadAction<AppChatMessage & { chat: { id: string } }>,
        ) => {
          const chatMessage = action.payload;
          updateChatMessageBucket(state, chatMessage);
        },
      ),
});
