import ReactDOM from "react-dom/client";
import { Root } from "./views/Root.tsx";
import "./env";

import * as Sentry from "@sentry/react";

if (window.location.host === "localhost") {
  Sentry.init({
    dsn: "https://0ebac22fc3a46884b710c6f8a890f29f@o4505507813326848.ingest.us.sentry.io/4507074944761856",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/dev\.dothatchat\.com/,
      /^https:\/\/chat\.dothatchat\.com/,
    ],
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(<Root />);
