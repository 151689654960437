import { useEffect } from "react";

type ThemeMode = "dark" | "light"; // Extend this type based on your theme modes
type ThemeChangeCallback = (mode: ThemeMode) => void;

/** Custom hook for detecting system theme changes */
export function useSystemTheme(callback: ThemeChangeCallback) {
  useEffect(() => {
    // Function to update theme based on system preference
    const updateTheme = () => {
      const newTheme: ThemeMode = window.matchMedia(
        "(prefers-color-scheme: dark)",
      ).matches
        ? "dark"
        : "light";
      callback(newTheme);
    };

    // Initial theme update
    updateTheme();

    // Listening for changes in the system theme
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQuery.addEventListener("change", updateTheme);

    // Cleanup function to remove event listener
    return () => mediaQuery.removeEventListener("change", updateTheme);
  }, [callback]);
}
