import React, {
  createContext,
  useContext,
  useState,
  FunctionComponent,
  ReactNode,
} from "react";

/**
 * Type definition for the configuration object used to create a named context.
 * @template T - The string literal representing the name of the context.
 * @template State - The type of the state managed by this context.
 */
type ContextConfig<T extends string, State> = {
  name: T;
  initialState: State;
};

/**
 * Type for the context state that includes both the state and a function to update it.
 * @template State - The type of the state managed by this context.
 */
type ContextState<State> = {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
};

/**
 * Creates a React context with a corresponding provider and hook, based on a specified name and initial state.
 * Allows partial or full overriding of the initial state through props.
 *
 * @template T - The string literal representing the name of the context.
 * @template State - The type of the state managed by this context.
 * @param config - Configuration object including the name and initial state.
 * @returns An object containing the custom hook and provider component.
 *
 * @example
 * ```typescript
 * // Creating a step context with initial state and optional state overrides
 * const { useStep, StepProvider } = createNamedContext({
 *   name: 'step',
 *   initialState: { currentIndex: 0 }
 * });
 * export { useStep, StepProvider };
 * ```
 */
export function createNamedContext<T extends string, State>({
  name,
  initialState,
}: ContextConfig<T, State>) {
  const formattedName = name.charAt(0).toUpperCase() + name.slice(1); // Capitalize first letter
  const Context = createContext<ContextState<State> | undefined>(undefined);

  const Provider: FunctionComponent<{
    children: ReactNode;
    overrideState?: State | Partial<State>;
  }> = ({ children, overrideState = initialState }) => {
    const [state, setState] = useState<State>(() =>
      typeof overrideState === "object" &&
      overrideState !== null &&
      !Array.isArray(overrideState)
        ? { ...initialState, ...(overrideState as Partial<State>) }
        : (overrideState as State),
    );
    return (
      <Context.Provider value={{ state, setState }}>
        {children}
      </Context.Provider>
    );
  };

  const useHook = (): [
    ContextState<State>["state"],
    ContextState<State>["setState"],
  ] => {
    const context = useContext(Context);
    if (!context) {
      throw new Error(
        `use${formattedName} must be used within a ${formattedName}Provider`,
      );
    }
    return [context.state, context.setState];
  };

  return {
    [`use${formattedName}`]: useHook,
    [`${formattedName}Provider`]: Provider,
  } as {
    [K in
      | `use${Capitalize<T>}`
      | `${Capitalize<T>}Provider`]: K extends `use${Capitalize<T>}`
      ? () => [ContextState<State>["state"], ContextState<State>["setState"]]
      : FunctionComponent<{
          children: ReactNode;
          overrideState?: State | Partial<State>;
        }>;
  };
}
