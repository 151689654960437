/**
 * @overview Manages dynamic data buckets with add, update, and remove functionalities. This implementation
 * leverages Redux Toolkit for efficient state management, enabling seamless integration with asynchronous
 * data fetching and updating mechanisms. Designed to be highly flexible and extensible, it supports
 * additional behaviors through extraReducers, facilitating complex data handling and interaction patterns
 * within Redux applications.
 */

import { UniqueIdentifier } from "@dnd-kit/core";
import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";

export type Bucket<T, Meta> = {
  [id: string]: BucketItem<T, Meta>;
};

// Defines the structure of each item within a bucket.
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export interface BucketItem<T, Meta = any> {
  status: BucketItemStatus;
  data: T;
  meta: Meta;
  error: string | null;
  lastUpdated: number;
}

// Represents the status of a bucket item.
export type BucketItemStatus =
  | "uninitialized"
  | "pending"
  | "fulfilled"
  | "rejected";

/**
 * Creates a dynamic data bucket slice for Redux Toolkit, providing a standard approach to managing
 * a collection of data items, each represented by an ID and containing data, status, error information,
 * and a last updated timestamp. This utility function leverages Redux Toolkit's createSlice to facilitate
 * easy state management and is designed to be extensible, allowing additional reducers to be integrated
 * through its extraReducers parameter.
 */
export function createBucket<T, N extends string, Meta>(
  name: N,
  {
    extraReducers,
  }: {
    extraReducers: (builder: ActionReducerMapBuilder<Bucket<T, Meta>>) => void;
  },
) {
  const initialState: Bucket<T, Meta> = {};

  const slice = createSlice({
    name: `bucket_${name}`,
    initialState,
    reducers: {
      // Adds or updates an item in the bucket with the given ID and data.
      addItem(
        state,
        action: PayloadAction<{ id: string; data: T; meta: Meta }>,
      ) {
        const { id, data, meta } = action.payload;
        state[id] = {
          status: "fulfilled",
          data: castDraft(data),
          meta: castDraft(meta),
          error: null,
          lastUpdated: Date.now(),
        };
      },
      // Updates an existing item in the bucket with new data.
      updateItem(
        state,
        action: PayloadAction<{ id: string; data?: T; meta?: Meta }>,
      ) {
        const { id, data, meta } = action.payload;
        if (state[id]) {
          const isDataChanged = data !== undefined;
          const isMetaChanged = meta !== undefined;
          if (!isDataChanged && !isMetaChanged) return;

          if (isDataChanged) state[id].data = castDraft(data);
          if (isMetaChanged) state[id].meta = castDraft(meta);
          state[id].lastUpdated = Date.now();
        }
      },
      // Removes an item from the bucket by its ID.
      removeItem(state, action: PayloadAction<{ id: UniqueIdentifier }>) {
        const { id } = action.payload;
        if (state[id]) delete state[id];
      },
      clearState() {
        return initialState;
      },
    },
    extraReducers,
  });

  return slice;
}
