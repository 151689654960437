// AbstractHandleClass.ts
import type { AppDispatch, AppGetState } from "../store";
import { store } from "../store";

export interface AbstractHandleClassOptions {
  getStore?: AppGetState;
  dispatch?: AppDispatch;
}

export abstract class AbstractHandleClass<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends AbstractHandleClassOptions = {}
> {
  protected getStore: AppGetState;
  protected dispatch: AppDispatch;

  constructor(options: T = {} as T) {
    this.getStore = options.getStore || store.getState;
    this.dispatch = options.dispatch || store.dispatch;
  }
}
