import { useEffect } from "react";
import { useAppSelector } from "~/redux/store";
import { WebSocketAppSingleton } from "~/utils/WebSocketSingleton/WebSocketAppSingleton";
import { AccountUpdatedSubscription } from "~/utils/WebSocketSingleton/subscriptions/AccountUpdatedSubscription";

const accountUpdatedSubscription = new AccountUpdatedSubscription();

export function WebSocketProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const auth = useAppSelector((state) => state.auth.token);

  useEffect(() => {
    if (auth) {
      const instance = WebSocketAppSingleton.getInstance();
      if (!instance.hasSubscription(accountUpdatedSubscription.id)) {
        instance.addSubscription(accountUpdatedSubscription);
      }
    }
  }, [auth]);

  return <>{children}</>;
}
