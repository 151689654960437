/** @overview - Manages redux store configuration and typed selector hooks. */
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import { rootReducer } from "./reducers/rootReducer";
import { api } from "./api.generated";
import { rtkQueryErrorLogger } from "./middleware/errorToast";

const REDUX_PERSIST_ACTIONS: string[] = [
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
];

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  middleware: ((getDefaultMiddleware: any) =>
    Array.prototype
      .concat(
        getDefaultMiddleware({
          serializableCheck: { ignoredActions: [...REDUX_PERSIST_ACTIONS] },
        }),
        api.middleware,
        rtkQueryErrorLogger,
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter(Boolean)) as any,
});

export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;
export type AppRootState = ReturnType<AppGetState>;

/** Typed dispatch hook but with support for thunks. */
export const useAppDispatch = (): AppDispatch => {
  const dispatch = useDispatch();

  return dispatch;
};

export const useAppSelector: TypedUseSelectorHook<AppRootState> = useSelector;
