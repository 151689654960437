/** @overview - Manages authentication slice of redux store. */
import { createSlice } from "@reduxjs/toolkit";

import _ from "lodash";
import { api } from "../api.generated";
import { AppSchema, AppUser } from "../apiTypes";

export const initialState = {
  /** Authenticated User */
  user: <null | AppUser>null,
  /** Authentication Token */
  token: <null | string>null,
};

export type AuthState = typeof initialState;

export const slice = createSlice({
  name: "auth" as const,
  initialState,
  reducers: {
    signOut(state) {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(api.endpoints.whoAmI.matchFulfilled, (state, action) => {
        if (action.payload.me) {
          state.user = AppSchema.User.parse(action.payload.me);
        }
      })
      .addMatcher(
        api.endpoints.updateUser.matchFulfilled,
        (state, { payload }) => {
          if (payload.updateUser) {
            state.user = AppSchema.User.parse(payload.updateUser);
          }
        },
      )
      .addMatcher(
        api.endpoints.exchangeTempOauthTokenForAuthToken.matchFulfilled,
        (state, { payload }) => {
          if (_.isNil(payload.exchangeTempOauthTokenForAuthToken)) {
            console.error("exchangeTempOauthTokenForAuthToken is nil");
            return;
          }
          if ("token" in payload.exchangeTempOauthTokenForAuthToken === false) {
            console.error(
              "token is missing from exchangeTempOauthTokenForAuthToken response",
            );
            return;
          }
          if (_.isNil(payload.exchangeTempOauthTokenForAuthToken.token)) {
            console.error(
              "token is nil in exchangeTempOauthTokenForAuthToken response",
            );
            return;
          }
          state.token = payload.exchangeTempOauthTokenForAuthToken.token;
        },
      ),
});

export const { signOut } = slice.actions;

export const { reducer } = slice;
