import { createTransform } from "redux-persist";

import { api } from "../api.generated";
import _ from "lodash";

// Custom transform function for RTK Query slices
export const rtkQueryTransform = createTransform(
  // transform state on its way to being serialized and stored.
  (inboundState) => {
    if (typeof inboundState !== "object" || inboundState === null) {
      return inboundState;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newState: Record<string, any> = {};

    // TODO: needlessly iterating, simplify
    Object.keys(inboundState).forEach((key) => {
      if (key === "queries") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const q = (inboundState as any)[key];
        for (const [endpointKey, endpointValue] of Object.entries(q)) {
          if (endpointValue && typeof endpointValue === "object") {
            _.set(
              newState,
              `${key}.${endpointKey}.data`,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (endpointValue as any).data
            );
          }
        }
      }
    });

    return newState;
  },
  // transform state being rehydrated
  (
    outboundState
    // keyof
  ) => {
    return outboundState;
  },
  // define which reducers this transform gets called for.
  { whitelist: [api.reducerPath] }
);
