/**
 * Utilizes utility functions for managing one-to-one relationships within a Redux state.
 */

import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { TwinState, setTwinRelation, removeTwinRelation } from "./twinUtils";

export function createTwin<N extends string>(
  name: N,
  {
    extraReducers,
  }: {
    extraReducers?: (builder: ActionReducerMapBuilder<TwinState>) => void;
  } = {},
) {
  const initialState: TwinState = {};

  const slice = createSlice({
    name: `twin_${name}`,
    initialState,
    reducers: {
      setRelation(
        state,
        action: PayloadAction<{ key: string; value: string }>,
      ) {
        setTwinRelation(state, action.payload.key, action.payload.value);
      },
      removeRelation(state, action: PayloadAction<{ key: string }>) {
        removeTwinRelation(state, action.payload.key);
      },
      clearState() {
        return initialState;
      },
    },
    extraReducers,
  });

  return slice;
}
