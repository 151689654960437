import { FullError } from "./pages/FullError";

import { AppProvider } from "./providers/AppProvider";
import { AppRoutes } from "./routes/AppRoutes";

import { withErrorBoundary } from "@sentry/react";

export const Root = withErrorBoundary(
  function Root() {
    return (
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    );
  },
  {
    fallback: () => <FullError />,
    showDialog: true,
  },
);
