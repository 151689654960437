import { Elements, PaymentElement } from "@stripe/react-stripe-js";

import { useSystemTheme } from "~/utils/useSystemTheme";
import { StripePaymentElementChangeEvent, loadStripe } from "@stripe/stripe-js";

import { useState, useCallback, useEffect } from "react";
import { env } from "~/env";
import { CurrentUser } from "~/redux/handles/CurrentUser";
import { useAppSelector } from "~/redux/store";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export function EnterStripePaymentDetailsProvider({
  clientSecret,
  children,
}: {
  clientSecret: string | null;
  children: React.ReactNode;
}) {
  const [stripePromise] = useState(() => loadStripe(env.VITE_STRIPE_PK));

  const [theme, setTheme] = useState<"dark" | "light">("light");
  useSystemTheme(setTheme);

  if (!clientSecret) return <>{children}</>;
  return (
    <Elements
      // stripe={stripePromise}
      // options={{
      //   // clientSecret,
      //   mode: "setup",
      //   currency: "usd",
      //   appearance: { theme },
      // }}
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: theme === "dark" ? "night" : "stripe",
          variables: {
            colorPrimary: env.COLORS.primary[500],
          },
        },
      }}
    >
      {children}
    </Elements>
  );
}

export function EnterStripePaymentDetails({
  clientSecret,
}: {
  clientSecret: string;
}) {
  useEffect(() => {}, []);
  return (
    <>
      <div>
        <CheckoutForm clientSecret={clientSecret} />
      </div>
    </>
  );
}

function CheckoutForm({ clientSecret }: { clientSecret: string }) {
  const didAnythingChange = useCallback(
    (e: StripePaymentElementChangeEvent) => {
      console.log("stripe:didAnythingChange");
      console.log(e);
    },
    [],
  );

  const currentUser = useAppSelector((state) => new CurrentUser(() => state));

  const email = currentUser.getUserEmail();

  return (
    <form
      onSubmit={(e) => void e.preventDefault()}
      className="mx-auto max-w-xl"
    >
      {/* <LinkAuthenticationElement options={{ defaultValues: { email } }} /> */}
      {/* <hr className="my-4 dark:border-slate-700" /> */}
      <PaymentElement
        onChange={didAnythingChange}
        // onBlur={didAnythingChange}
        options={{ defaultValues: { billingDetails: { email } } }}
      />
    </form>
  );
}
