import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { api } from "../api.generated";

import { rtkQueryTransform } from "../utils/rtkQueryTransform";
import { AccountBucket } from "./buckets/AccountBucket";
import { UserBucket } from "./buckets/UserBucket";
import { ChatBucket } from "./buckets/ChatBucket";

import { AccountUserRope } from "./ropes/AccountUserRope";
import { AccountChatRope } from "./ropes/AccountChatRope";

import { ChatMessagesBucket } from "./buckets/ChatMessagesBucket";

import { slice as authSlice } from "./authSlice";

import { nonCachedChatViewSlice } from "./nonCachedChatViewSlice";
import { chatViewSlice } from "./chatViewSlice";
import { ChatIdSlugTwin } from "./twins/ChatIdSlugTwin";
import { noticeSlice } from "./noticeSlice";
import { messageListSlice } from "./messageListSlice";
import { modalSlice } from "./modalSlice";

const reducers = {
  // [API]
  [api.reducerPath]: api.reducer,
  // [BUCKETS]
  [AccountBucket.name]: AccountBucket.reducer,
  [UserBucket.name]: UserBucket.reducer,
  [ChatBucket.name]: ChatBucket.reducer,
  [ChatMessagesBucket.name]: ChatMessagesBucket.reducer,
  // [ROPE]
  [AccountUserRope.name]: AccountUserRope.reducer,
  [AccountChatRope.name]: AccountChatRope.reducer,
  // [TWIN]
  [ChatIdSlugTwin.name]: ChatIdSlugTwin.reducer,
  // [BUSINESS LOGIC]
  // [BUSINESS LOGIC] - AUTH
  [authSlice.name]: authSlice.reducer,
  // [BUSINESS LOGIC] - CHAT
  [chatViewSlice.name]: chatViewSlice.reducer,
  /**/ [nonCachedChatViewSlice.name]: nonCachedChatViewSlice.reducer,
  // [BUSINESS LOGIC] - CHAT - MESSAGES
  [messageListSlice.name]: messageListSlice.reducer,
  // [BUSINESS LOGIC] - TOAST
  [noticeSlice.name]: noticeSlice.reducer,
  // [BUSINESS LOGIC] - MODAL
  [modalSlice.name]: modalSlice.reducer,
};

export const unpersistedRootReducer = combineReducers(reducers);

export const rootReducer = persistReducer(
  {
    key: "do_that_chat",
    blacklist: [
      api.reducerPath,
      nonCachedChatViewSlice.name,
      messageListSlice.name,
    ],
    version: 6,
    storage,
    transforms: [rtkQueryTransform], // add the transform
  },
  // WHY: disabling with comment and overriding with `as` because
  //      the type error was highly inscrutable and this is effectively
  //      the same in the vast majority of cases.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unpersistedRootReducer as any,
) as typeof unpersistedRootReducer;
