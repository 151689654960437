/** @overview - Manages users bucket. */

import { api } from "~/redux/api.generated";
import { AppSchema, type AppUser } from "~/redux/apiTypes";
import { createBucket } from "~/redux/utils/createBucket";

export const UserBucket = createBucket<AppUser, "user", null>("user", {
  extraReducers: (builder) =>
    builder.addMatcher(api.endpoints.whoAmI.matchFulfilled, (state, action) => {
      if (action.payload.me) {
        const id = action.payload.me.id;
        state[id] = {
          status: "fulfilled" as const,
          data: AppSchema.User.parse(action.payload.me),
          error: null,
          meta: null,
          lastUpdated: Date.now(),
        };
      }
    }),
});
