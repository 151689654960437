import { fetchWithRetry } from "~/utils/fetchWithRetry";
import { WebSocketAppContext } from "../WebSocketAppSingleton";
import { WebSocketConnection } from "../WebSocketConnection";
import { WebSocketSubscription } from "../WebSocketSubscription";
import { store } from "~/redux/store";
import { SubscribeToAccountUpdatedDocument } from "~/redux/api.generated";
import { env } from "~/env";
import { WEBSOCKET__ACCOUNT_UPDATED } from "../WebSocketConstants";
import { z } from "zod";
import { CurrentUser } from "~/redux/handles/CurrentUser";

export class AccountUpdatedSubscription extends WebSocketSubscription<WebSocketAppContext> {
  channelName: string | null = null;
  authKey: string | null = null;
  wscId: string | null = null;

  public async subscribe(wsc: WebSocketConnection<WebSocketAppContext>) {
    console.log("AccountUpdatedSubscription.subscribe");
    console.log("this.wscId", this.wscId);
    console.log("wsc.id", wsc.id);
    if (this.wscId === wsc.id) return;

    console.log("uh");
    this.status = "initializing";
    this.wscId = wsc.id;

    await this.initialize(wsc);

    wsc.send(
      JSON.stringify({
        event: "pusher:subscribe",
        data: {
          auth: this.authKey,
          channel: this.channelName,
        },
      }),
    );
    this.status = "subscribed";
  }

  public async unsubscribe(wsc: WebSocketConnection<WebSocketAppContext>) {
    void wsc;
    this.status = "unsubscribed";
  }

  protected async initialize(wsc: WebSocketConnection<WebSocketAppContext>) {
    // Fetch the channel name for the WebSocket connection
    const socketId = wsc.context.socketId;

    // saving this.channelName and this.authKey
    const channelName = await this.fetchChannelName();
    await this.fetchAuthKey(channelName, socketId);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(rawEvent: any) {
    // REASON FOR ANY: this comes from the WebSocket API and we don't know
    //                 the structure of all the possible messages.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedEvent = JSON.parse(rawEvent.data) as {
      event: string;
      [x: string]: any;
    };
    // console.log("InitializationSubscription received message", event);
    switch (parsedEvent.event) {
      case "pusher_internal:subscription_succeeded": {
        break;
      }
      case "lighthouse-subscription": {
        const rawData = parsedEvent.data;
        const parsedData = JSON.parse(rawData);

        // const safeParseResult =
        //   this.accountUpdatedValidator.safeParse(parsedData);

        // if (safeParseResult.success === false) return;
        const payload = parsedData.result.data.accountUpdated;
        if (!payload) return;

        store.dispatch({
          type: WEBSOCKET__ACCOUNT_UPDATED,
          payload,
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  //============================================================================
  // PRIVATE METHODS
  //============================================================================

  accountUpdatedValidator = z.object({
    more: z.boolean(),
    result: z.object({
      data: z.object({
        accountUpdated: z.object({
          id: z.string(),
          credit_amount: z.string(),
          account_name: z.string(),
          is_primary: z.boolean(),
        }),
      }),
    }),
  });

  /**
   * Fetches the channel name for the WebSocket subscription.
   * @returns {Promise<string>} The channel name.
   */
  private async fetchChannelName(): Promise<string> {
    // TODO: see if this can be cached
    // if (this.channelName) return this.channelName;

    /** Expected structure of a response from fetching a channel name. */
    interface FetchChannelNameResponse {
      data: { accountUpdated: null };
      extensions: {
        lighthouse_subscriptions: { channel: `private-lighthouse-${string}` };
      };
    }
    const response: FetchChannelNameResponse = await fetchWithRetry({
      url: `${env.VITE_API_URL}/graphql`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${store.getState().auth.token}`,
        },
        body: JSON.stringify({
          query: SubscribeToAccountUpdatedDocument,
          variables: {
            accountId: new CurrentUser(store).getPrimaryAccountId(),
          },
        }),
      },
    });

    // Return the channel name from the response
    this.channelName = response.extensions.lighthouse_subscriptions.channel;
    return this.channelName;
  }

  /**
   * Fetches the authorization key for subscribing to a channel.
   * @param {string} channelName The name of the channel.
   * @param {string} socketId The socket ID.
   * @returns {Promise<string>} The authorization key.
   */
  private async fetchAuthKey(
    channelName: string,
    socketId: string,
  ): Promise<string> {
    // TODO: see if this can be cached
    // if (this.authKey) return this.authKey;

    const authResult = await fetchWithRetry<{
      auth: string;
    }>({
      url: `${env.VITE_API_URL}/graphql/subscriptions/auth`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          channel_name: channelName,
          socket_id: socketId,
        }),
      },
    });

    this.authKey = authResult.auth;
    return this.authKey;
  }
}
