import z from "zod";

import { colors } from "../tailwind.config.js";

export const env = (() => {
  const env = z
    .object({
      VITE_API_URL: z.string(),
      VITE_GOOGLE_OAUTH_URL: z.string(),
      VITE_SUBSCRIPTION_RETURN_URL: z.string(),
      VITE_GOOGLE_ANALYTICS_ID: z.string(),
      VITE_WS_URL: z.string(),
      VITE_STRIPE_PK: z.string(),
      VITE_STRIPE_RETURN_URL: z.string(),
      BASE_URL: z.string(),
      MODE: z.string(),
      DEV: z.boolean(),
      PROD: z.boolean(),
      SSR: z.boolean(),
    })
    .parse(import.meta.env);

  // Check if the current subdomain is 'dev' and adjust VITE_API_URL accordingly
  if (window.location.hostname.startsWith("dev.")) {
    env.VITE_API_URL = "https://dev.api.dothatchat.com";
    env.VITE_GOOGLE_OAUTH_URL =
      "https://dev.api.dothatchat.com/api/oauth/google/web";
    env.VITE_WS_URL = "wss://dev.websocket.dothatchat.com/app/do-that-reverb";
  }

  return Object.freeze({
    ...env,
    COLORS: colors,
  });
})();

window.env = env;
