import { useEffect, useState } from "react";
import { cn } from "~/utils/cn";

export function ToastContainer({
  visible,
  children,
}: {
  visible: boolean;
  children: React.ReactNode;
}) {
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => void setIsInitialRender(false), 1);
    return () => clearTimeout(timeout);
  }, []);

  const [isHiding, setIsHiding] = useState(visible);

  useEffect(() => {
    if (visible) return void setIsHiding(false);

    const timeout = setTimeout(() => void setIsHiding(true), 300);
    return () => {
      setIsHiding(false);
      clearTimeout(timeout);
    };
  }, [visible]);

  if (isHiding) return null;
  return (
    <div
      className={cn(
        "max-w-md w-full bg-white dark:bg-slate-900 dark:text-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 transition-[opacity,transform] transform duration-300 ease-out",
        {
          "opacity-0 scale-95 translate-x-full": isInitialRender,
          "opacity-1 scale-100": visible && !isInitialRender,
          "opacity-0 scale-50 pointer-events-none": !visible,
        }
      )}
    >
      {children}
    </div>
  );
}
