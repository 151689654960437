/**
 * Abstracts fetch API calls into a generic function with indefinite retries using exponential backoff.
 * @param {FetchWithRetryParams} params Parameters object that includes the URL and Fetch request options.
 * @returns {Promise<T>} The fetched response data.
 */
export async function fetchWithRetry<T>({
  url,
  options,
}: {
  url: string;
  options?: RequestInit;
}): Promise<T> {
  let retryCount = 0;
  const maxDelay = 10000; // Maximum delay cap in milliseconds (e.g., 10000ms = 10 seconds)

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error(`Fetch attempt failed, retry #${retryCount}:`, error);
      retryCount++;
      // Calculate delay with exponential backoff
      const delay = Math.min(100 * 2 ** (retryCount - 1), maxDelay); // 100ms * 2^retryCount, capped at maxDelay
      console.info(`Waiting ${delay}ms before retrying...`);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
}
