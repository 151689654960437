export function CloseButton({
  onClose,
}: {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <div className="flex border-l border-gray-200 dark:border-gray-700">
      <button
        onClick={onClose}
        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
      >
        Close
      </button>
    </div>
  );
}
