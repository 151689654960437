import React, { ReactNode } from "react";
import "../App.css";
import { Helmet } from "react-helmet-async";
import { browser } from "../../utils";

export function StyleProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>DoThatChat</title>
        {browser.isIosSafari && (
          <>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, minimal-ui"
            />
          </>
        )}
      </Helmet>

      {children}
    </>
  );
}
