/** @overview - Manages chats bucket. */

import _ from "lodash";
import { z } from "zod";
import { api } from "~/redux/api.generated";
import { AppChat, AppSchema } from "~/redux/apiTypes";
import { createBucket } from "~/redux/utils/createBucket";

const parse = (
  data: Parameters<(typeof AppSchema)["Chat"]["parse"]>[0],
): z.infer<typeof AppSchema.Chat> => {
  // use safeparse and console.error
  const safeParseResult = AppSchema.Chat.safeParse(data);
  if (!safeParseResult.success) {
    console.error(safeParseResult.error);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return data as any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function updateBucket(state: any, chat: any) {
  try {
    const {
      most_recent_chat_message,
      most_recent_golden_message,
      first_chat_message,
      first_golden_message,
      golden_path_messages,
      ...chatData
    } = chat;

    const prevTotalGoldenPathMessages =
      state[chat.id]?.meta.total_golden_path_messages;

    const newTotalGoldenPathMessages = Math.max(
      golden_path_messages?.paginatorInfo?.total ?? 0,
      prevTotalGoldenPathMessages ?? 0,
    );

    const data = {
      ..._.get(state, [chat.id, "data"], {}),
      ...parse(chatData),
    };

    if (state[chat.id] === undefined) {
      state[chat.id] = {
        status: "fulfilled",
        error: null,
        meta: {
          most_recent_chat_message_id: most_recent_chat_message?.id ?? null,
          most_recent_golden_message_id: most_recent_golden_message?.id ?? null,
          first_chat_message_id: first_chat_message?.id ?? null,
          first_golden_message_id: first_golden_message?.id ?? null,
          total_golden_path_messages: newTotalGoldenPathMessages,
        },
        lastUpdated: Date.now(),
        data,
      };
    } else {
      state[chat.id] = {
        ...state[chat.id],
        meta: {
          ...state[chat.id].meta,
          most_recent_chat_message_id: most_recent_chat_message?.id ?? null,
          most_recent_golden_message_id: most_recent_golden_message?.id ?? null,
          first_chat_message_id: first_chat_message?.id ?? null,
          first_golden_message_id: first_golden_message?.id ?? null,
          total_golden_path_messages: newTotalGoldenPathMessages,
        },
        lastUpdated: Date.now(),
        data,
      };
    }
  } catch (e) {
    console.error(e);

    throw e;
  }
}

export const ChatBucket = createBucket<
  AppChat,
  "Chat",
  {
    most_recent_golden_message_id: string | null;
    most_recent_chat_message_id: string | null;
    first_chat_message_id: string | null;
    first_golden_message_id: string | null;
    total_golden_path_messages: number | null;
  }
>("Chat", {
  extraReducers: (builder) =>
    builder
      .addMatcher(
        api.endpoints.getConversationsForAccount.matchFulfilled,
        (state, action) => {
          const chatArr = action.payload.getAccount?.chats.data ?? [];
          for (let i = 0; i < chatArr.length; i++) {
            const chat = action.payload.getAccount?.chats.data[i];
            updateBucket(state, chat);
          }
        },
      )
      .addMatcher(
        api.endpoints.getConversation.matchFulfilled,
        (state, action) => {
          const chat = action.payload.getConversation;
          updateBucket(state, chat);
        },
      )
      .addMatcher(api.endpoints.createChat.matchFulfilled, (state, action) => {
        const chat = action.payload.createConversation;
        updateBucket(state, chat);
      })
      .addMatcher(
        api.endpoints.sendChatToTrashBin.matchFulfilled,
        (state, action) => {
          const chat = action.payload.sendChatToTrashBin;
          updateBucket(state, chat);
        },
      ),
});
