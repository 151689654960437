import { fetchWithRetry } from "~/utils/fetchWithRetry";
import { WebSocketAppContext } from "../WebSocketAppSingleton";
import { WebSocketConnection } from "../WebSocketConnection";
import { WebSocketSubscription } from "../WebSocketSubscription";
import { store } from "~/redux/store";
import { env } from "~/env";
import { z } from "zod";
import { WEBSOCKET__PARTIAL_CHAT_COMPLETION_RECEIVED } from "../WebSocketConstants";

export class ChatMessageStreamingSubscription extends WebSocketSubscription<WebSocketAppContext> {
  wscId: string | null = null;

  constructor(
    public id: string,
    public chatId: string,
  ) {
    super(id);
  }
  public async subscribe(wsc: WebSocketConnection<WebSocketAppContext>) {
    if (this.wscId === wsc.id) return;
    this.wscId = wsc.id;

    this.status = "initializing";
    await this.initialize(wsc);
    this.status = "subscribed";
  }

  public async unsubscribe(wsc: WebSocketConnection<WebSocketAppContext>) {
    wsc.sendJson({
      event: "pusher:unsubscribe",
      data: {
        channel: `private-chats.${this.chatId}.messages.stream`,
      },
    });
  }

  protected async initialize(wsc: WebSocketConnection<WebSocketAppContext>) {
    const authResult = await fetchWithRetry<{ auth: string }>({
      url: `${env.VITE_API_URL}/broadcasting/auth`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${store.getState().auth.token}`,
        },
        body: JSON.stringify({
          socket_id: wsc.context.socketId,
          channel_name: `private-chats.${this.chatId}.messages.stream`,
        }),
      },
    });

    console.log("authResult", authResult);
    const payload = {
      event: "pusher:subscribe",
      data: {
        auth: authResult.auth,
        channel: `private-chats.${this.chatId}.messages.stream`,
      },
    };
    console.log("JSON IM SENDING", payload);
    wsc.sendJson(payload);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(rawEvent: any) {
    // REASON FOR ANY: this comes from the WebSocket API and we don't know
    //                 the structure of all the possible messages.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedEvent = JSON.parse(rawEvent.data) as {
      event: string;
      [x: string]: any;
    };

    switch (parsedEvent.event) {
      case "Modules\\ChatDispatcherModule\\App\\Events\\MessageStreamEventOccurred": {
        const rawData = parsedEvent.data;
        const parsedData = JSON.parse(rawData);

        const validatedData =
          this.messageStreamEventValidator.parse(parsedData);

        store.dispatch({
          type: WEBSOCKET__PARTIAL_CHAT_COMPLETION_RECEIVED,
          payload: validatedData,
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  private messageStreamEventValidator = z.object({
    chat_message_id: z.string(),
    parent_message_id: z.string(),
    message_string: z.string(),
  });
}
