/** @overview - Manages wrapper components for app.  */

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { store } from "~/redux/store";

export const persistor = persistStore(store);

/** Provider components needed for redux. */
export const ReduxProvider = ({
  children,
}: {
  /** Root of the App. */
  children: React.ReactNode;
}): JSX.Element => (
  <Provider store={store}>
    {/* 
    TODO: Make sure the loading screen here is full and nice instead of just
    <h2>loading</h2>
     */}
    <PersistGate loading={<h2>loading</h2>} persistor={persistor}>
      {children}
    </PersistGate>
  </Provider>
);
