/** @overview - Redux slice for managing message-related states including drafted messages and index lookups for messages. */

import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { v4 } from "uuid";
import { api } from "../api.generated";
import { AppRootState } from "../store";
import {
  WEBSOCKET__CHAT_MESSAGE_UPDATED,
  WEBSOCKET__PARTIAL_CHAT_COMPLETION_RECEIVED,
} from "~/utils/WebSocketSingleton/WebSocketConstants";
import { AppChatMessage } from "../apiTypes";

/** Represents a drafted message with various attributes including author type and conversation linkage. */
export interface DraftedMessage {
  id: string;
  text: string | null;
  createdAt: string; // ISO 8601 formatted date-time string.
  sentAt: string | null; // ISO 8601 formatted date-time string or null if not sent.
  parentId: string | null; // Parent message identifier if applicable.
  conversationId: string; // Identifier for the conversation this message is associated with.
  authorType: "USER" | "BOT"; // Designation of the message's author as either a user or a bot.
}

/** The initial state for drafted messages, including a map of message IDs to drafted message details and tracking of partial AI chat completions. */
const initialState = {
  draftedMessagesMap: <{ [draftedMessageId: string]: DraftedMessage }>{},
  primaryDraftedConversationId: <string>v4(),

  primaryAiDraftedMessageId: <string | null>null,
  partialChatCompletionsByParentId: <
    { [aiDraftedMessageParentId: string]: string }
  >{},

  mapChatToIndexToMessageInfo: {} as {
    [chatId: string]: {
      [index: number]: {
        messageId: string;
        type: "API" | "DRAFT";
      };
    };
  },
};

/** Redux slice for managing all aspects of message-related state, combining drafted messages and index lookups. */
export const messageListSlice = createSlice({
  name: "messageList",
  initialState,
  reducers: {
    /** Action to change the text of a drafted message. */
    changeTextOfDraftedMessage(
      state,
      action: PayloadAction<{ id: string; text: string }>,
    ) {
      const { id, text } = action.payload;
      if (!(id in state.draftedMessagesMap)) {
        state.draftedMessagesMap[id] = {
          id,
          text,
          createdAt: new Date().toISOString(),
          sentAt: null,
          parentId: null,
          conversationId: state.primaryDraftedConversationId,
          authorType: "USER",
        };
      } else if (state.draftedMessagesMap[id]) {
        state.draftedMessagesMap[id].text = text;
      }
    },
    /** Action to discard a drafted message by its ID. */
    discardDraftedMessageById(state, action: PayloadAction<string>) {
      const id = action.payload;
      delete state.draftedMessagesMap[id];
    },
    /** Action to set a complete drafted message into the state. */
    setDraftedMessage(state, action: PayloadAction<DraftedMessage>) {
      const draftedMessage = action.payload;
      state.draftedMessagesMap[draftedMessage.id] = draftedMessage;
    },
    /** Action to set the message ID and type at a specific index for a chat. */
    setChatToIndexToMessageId(
      state,
      action: PayloadAction<{
        chatId: string;
        index: number;
        messageId: string;
        type: "API" | "DRAFT";
      }>,
    ) {
      const { chatId, index, messageId, type } = action.payload;
      if (!(chatId in state.mapChatToIndexToMessageInfo)) {
        state.mapChatToIndexToMessageInfo[chatId] = {};
      }
      state.mapChatToIndexToMessageInfo[chatId][index] = { messageId, type };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        _.matches({ type: WEBSOCKET__PARTIAL_CHAT_COMPLETION_RECEIVED }),
        (
          state,
          action: PayloadAction<{
            chat_message_id: string;
            parent_message_id: string;
            message_string: string;
          }>,
        ) => {
          const { parent_message_id, chat_message_id, message_string } =
            action.payload;
          console.log("WEBSOCKET__PARTIAL_CHAT_COMPLETION_RECEIVED", {
            parent_message_id,
            chat_message_id,
            message_string,
          });
          if (state.primaryAiDraftedMessageId !== chat_message_id) {
            state.primaryAiDraftedMessageId = chat_message_id;
          }
          state.partialChatCompletionsByParentId[parent_message_id] =
            (state.partialChatCompletionsByParentId[parent_message_id] || "") +
            message_string;
        },
      )
      .addMatcher(
        _.matches({ type: WEBSOCKET__CHAT_MESSAGE_UPDATED }),
        (
          state,
          action: PayloadAction<AppChatMessage & { chat: { id: string } }>,
        ) => {
          console.log("action", action);
          // if this doesn't match primaryAiDraftedMessageId then ignore
          const isItDone =
            (action.payload as any).message_transactions[0].status === "DONE";
          if (!isItDone) return;
          const isThisForThePrimaryAiDraftedMessage =
            state.primaryAiDraftedMessageId === action.payload.id;
          if (!isThisForThePrimaryAiDraftedMessage) return;
          // otherwise, replace the highest index item with this item
          const chatId = action.payload.chat.id;
          const highestIndex = (() => {
            let highestIndex = -1;
            for (const index in state.mapChatToIndexToMessageInfo[chatId]) {
              if (
                state.mapChatToIndexToMessageInfo[chatId][index].type ===
                "DRAFT"
              ) {
                highestIndex = Math.max(highestIndex, parseInt(index));
              }
            }
            return highestIndex;
          })();

          state.mapChatToIndexToMessageInfo[chatId][highestIndex] = {
            messageId: action.payload.id,
            type: "API",
          };
          state.primaryAiDraftedMessageId = null;
        },
      )
      .addMatcher(
        api.endpoints.getGoldenPathMessages.matchFulfilled,
        (state, action) => {
          const goldenPathMessages =
            action.payload.getConversation.golden_path_messages.data;
          const chatId = action.payload.getConversation.id;
          const pageNum =
            action.payload.getConversation.golden_path_messages.paginatorInfo
              .currentPage;
          const numPerPage =
            action.payload.getConversation.golden_path_messages.paginatorInfo
              .perPage;

          for (let i = 0; i < goldenPathMessages.length; i++) {
            const goldenPathMessage = goldenPathMessages[i];
            const calculatedIndex = (pageNum - 1) * numPerPage + i;

            if (!(chatId in state.mapChatToIndexToMessageInfo)) {
              state.mapChatToIndexToMessageInfo[chatId] = {};
            }
            state.mapChatToIndexToMessageInfo[chatId][calculatedIndex] = {
              messageId: goldenPathMessage.id,
              type: "API",
            };
          }
        },
      );
  },
});

/** Exported actions and types from the combined message list slice. */
export const {
  changeTextOfDraftedMessage,
  discardDraftedMessageById,
  setDraftedMessage,
  setChatToIndexToMessageId,
} = messageListSlice.actions;

/** Selector to retrieve specific message details by index for a given chat ID, providing message ID and type if available. */
export const selectMessageInfoByIndexForChat = createSelector(
  [
    (state: AppRootState) => state.messageList.mapChatToIndexToMessageInfo,
    (state: AppRootState, chatId: string) => chatId,
    (state: AppRootState, chatId: string, index: number) => index,
  ],
  (mapChatToIndexToMessageInfo, chatId, index) => {
    const messageInfos = mapChatToIndexToMessageInfo[chatId];
    if (!messageInfos) return null;

    const messageInfo = messageInfos[index];
    if (messageInfo === undefined) return null;

    return messageInfo;
  },
);
