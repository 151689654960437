/** @overview - Redux slice for managing non-cached chat view state. */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/** The initial state for the non-cached chat view slice. */
const initialState = {
  /** Indicates whether the chat view is in the middle. */
  isDesktopSidebarOpen: <boolean>true,
  /** Selected engine */
  selectedEngineId: <string | null>null,
};

export type ChatViewState = typeof initialState;

/** Redux slice for managing non-cached chat view state. */
export const chatViewSlice = createSlice({
  name: "chatView",
  initialState,
  reducers: {
    /** Sets whether the desktop sidebar is open */
    setIsDesktopSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isDesktopSidebarOpen = action.payload;
    },
    /** Sets the selected engine */
    setSelectedEngineId(
      state,
      action: PayloadAction<ChatViewState["selectedEngineId"]>,
    ) {
      state.selectedEngineId = action.payload;
    },
  },
});

/** Exports actions from the chatViewSlice slice. */
export const {
  //
  setIsDesktopSidebarOpen,
  setSelectedEngineId,
} = chatViewSlice.actions;
