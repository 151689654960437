/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from "zod";

export const AppSchema = {
  User: z.object({
    id: z.string(),
    created_at: z.string(),
    email_verified_at: z.string().nullable(),
    email: z.string(),
    family_name: z.string().nullable(),
    given_name: z.string().nullable(),
    updated_at: z.string(),
  }),
  Account: z.object({
    id: z.string(),
    account_name: z.string().nullable(),
    something: z.any(),
    credit_amount: z.string().nullable(),
  }),
  Chat: z.object({
    id: z.string(),
    name: z.string().nullable(),
    prompt: z.string().nullable(),
    slug: z.string(),
    updated_at: z.string(),
    created_at: z.string(),
    trash_binned_at: z.string().nullable(),
  }),
  ChatMessage: z.object({
    id: z.string(),
    created_at: z.string(),
    message: z.string(),
    updated_at: z.string(),
    source: z.union([z.literal("USER"), z.literal("BOT")]),
  }),
};

export type AppUser = z.infer<(typeof AppSchema)["User"]>;
export type AppAccount = z.infer<(typeof AppSchema)["Account"]>;
export type AppChat = z.infer<(typeof AppSchema)["Chat"]>;
export type AppChatMessage = z.infer<(typeof AppSchema)["ChatMessage"]>;
