import React from "react";
import { WebSocketAppSingleton } from "~/utils/WebSocketSingleton/WebSocketAppSingleton";

export function Demo() {
  const onClick = () => {
    WebSocketAppSingleton.getInstance();
  };

  return (
    <div>
      <h1>Demo</h1>
      <p>this is is a demo page. It's not doing anything right now.</p>
      <button onClick={onClick}>click to initialize WebSocketConnection</button>
    </div>
  );
}
