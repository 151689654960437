/**
 * @overview
 * Imports createApi from Redux Toolkit's query/react library.
 * This utility function helps generate APIs that use Redux for state management.
 */

import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { type AppRootState } from "./store";
import { env } from "~/env";
import toast from "react-hot-toast";

/** Minimal GraphQL Client  */
const client = new GraphQLClient(`${env.VITE_API_URL}/graphql`);

/** Prepares the headers for a request, adding token if it is in the state. */
export const prepareHeaders = (
  headers: Headers,
  { getState: _getState }: { getState: unknown },
) => {
  const getState = _getState as () => AppRootState;
  const auth = getState().auth;
  if (auth === undefined) return headers;
  const { token } = auth;
  if (token) headers.set("Authorization", `Bearer ${token}`);

  return headers;
};

/** @deprecated ❗IMPORT FROM `api.generated.ts` INSTEAD ❗ */
export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    client,
    prepareHeaders,
    customErrors: (args) => {
      // console.log("args", args);
      // console.log("typeof args", typeof args);
      // console.log("args instanceof Error", args instanceof Error);
      // console.log("args.message", args.message);
      // console.log("args.name", args.name);
      // console.log("args.request", args.request);
      // console.log("args.response", args.response);
      // console.log("args.stack", args.stack);
      const errors = args.response.errors ?? [];
      for (let i = 0; i < errors.length; i++) {
        const error = errors[i];
        const msg = error.message as string;
        if (error.extensions.category === "authentication") continue;

        toast.error(msg, {
          position: "top-center",
          duration: 10_000,
        });
      }
      return args.message;
    },
  }),
  // global configuration for the api
  keepUnusedDataFor: 30,
  refetchOnMountOrArgChange: true,
  endpoints: () => ({}),
});
