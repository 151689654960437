import { createRope } from "~/redux/utils/createRope";
// import { api } from "~/redux/api.generated";
// import { addBidirectionalLink } from "~/redux/utils/ropeUtils";

export const AccountChatRope = createRope("Account_Chat", {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  extraReducers: (builder) => ({}),
  // builder.addMatcher(
  //   api.endpoints.getChats.matchFulfilled,
  //   (state, action) => {
  //     for (let i = 0; i < action.payload.me.owned_accounts.length; i++) {
  //       const ownedAccount = action.payload.me.owned_accounts[i];

  //       for (let j = 0; j < ownedAccount.chats.data.length; j++) {
  //         const chat = ownedAccount.chats.data[j];

  //         addBidirectionalLink(state, ownedAccount.id, chat.id);
  //       }
  //     }
  //   }
  // ),
});
