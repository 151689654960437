import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ProgressSteps } from "./ProgressSteps";
import type { Step } from "./ProgressSteps";

interface StepComponentProps {
  currentStepIndex: number;
}

interface ProgressBoxProps {
  steps: Step[];
  stepComponents: React.FC<StepComponentProps>[];
  stepDx?: number;
  onStepChange?: (index: number) => void;
}

export function ProgressBox({
  steps,
  stepComponents,
  stepDx = 0,
  onStepChange,
}: ProgressBoxProps) {
  const [currentStepIndex, setCurrentStepIndex] = useState(stepDx);

  const handleStepClick = (index: number) => {
    setCurrentStepIndex(index);
    onStepChange?.(index);
  };

  useEffect(() => {
    setCurrentStepIndex(stepDx);
  }, [stepDx]);

  return (
    <div className="overflow-x-hidden overflow-y-scroll">
      <ProgressSteps
        currentStepIndex={currentStepIndex}
        steps={steps}
        onStepClick={(e, step, index) => handleStepClick(index)}
      />
      <AnimatePresence>
        <motion.div
          className="flex"
          initial={false}
          animate={{
            x: `-${currentStepIndex * 100}%`,
          }}
          transition={{
            type: "tween",
            ease: "easeInOut",
            duration: 0.5,
          }}
        >
          {stepComponents.map((Component, index) => (
            <div
              className="h-96 w-full flex-none"
              key={index}
              style={{
                visibility: index === currentStepIndex ? "visible" : "hidden",
                transition: "visibility 0.5s ease-in-out",
              }}
            >
              <Component currentStepIndex={index} />
            </div>
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
