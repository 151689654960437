import { env } from "~/env";
import { WebSocketConnection } from "./WebSocketConnection";
import { ChatMessageUpdatedSubscription } from "./subscriptions/ChatMessageUpdatedSubscription";
import { ChatMessageStreamingSubscription } from "./subscriptions/ChatMessageStreamingSubscription";

export interface WebSocketAppContext {
  socketId: string;
}

export class WebSocketAppSingleton {
  public static getInstance() {
    // Depending on your editor, you may see `instance` formatted like a deprecated property.
    // This is because the `instance` property is marked as deprecated to discourage direct access.
    // (Instead, use the `getInstance()` method to access the singleton instance.)
    // Here, we need to access the instance directly to initialize and return it.
    if (!WebSocketAppSingleton.instance) {
      WebSocketAppSingleton.instance =
        new WebSocketConnection<WebSocketAppContext>({
          url: env.VITE_WS_URL,
          initializer: this.initializer,
        }).addSubscription(new ChatMessageUpdatedSubscription());
    }
    window.WebSocketConnection = WebSocketAppSingleton.instance;
    return WebSocketAppSingleton.instance;
  }

  public static subscribeToChatMessageStream(chatId: string) {
    if (typeof chatId !== "string") {
      throw new Error("chatId must be a string");
    }
    WebSocketAppSingleton.getInstance().addSubscription(
      new ChatMessageStreamingSubscription(chatId, chatId),
    );
  }

  public static unsubscribeToChatMessageStream(chatId: string) {
    WebSocketAppSingleton.getInstance().removeSubscription({ id: chatId });
  }

  /** @deprecated DO NOT INSTANTIATE THIS CLASS */
  constructor() { throw new Error("Do not instantiate this class"); } // prettier-ignore
  /** @deprecated DONT ACCESS DIRECTLY; USE `WebSocketAppSingleton.getInstance()` */
  private static instance: WebSocketConnection<WebSocketAppContext>;

  private static async initializer(ws: WebSocket) {
    const socketId: string = await new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function messageReceiver(event: MessageEvent<any>) {
        const data = JSON.parse(event.data);

        if (data.event === "pusher:connection_established") {
          const detail = JSON.parse(data.data);
          cleanup(); // IMPORTANT: Remove event listeners to prevent memory leaks
          resolve(detail.socket_id);
        }
      }
      function errorReceiver(event: Event) {
        cleanup(); // IMPORTANT: Remove event listeners to prevent memory leaks
        reject(event);
      }

      function cleanup() {
        ws.removeEventListener("message", messageReceiver);
        ws.removeEventListener("error", errorReceiver);
      }
      ws.addEventListener("message", messageReceiver);
      ws.addEventListener("error", errorReceiver);
    });

    return {
      context: { socketId },
    };
  }
}
