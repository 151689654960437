/**
 * Utility functions for managing one-to-one relationships in a Redux state.
 */

export interface TwinState {
  [key: string]: string;
}

/**
 * Sets or updates a bi-directional one-to-one relationship between two keys.
 */
export function setTwinRelation(
  state: TwinState,
  key: string,
  value: string
): void {
  state[key] = value;
  state[value] = key;
}

/**
 * Removes a bi-directional one-to-one relationship for a given key.
 */
export function removeTwinRelation(state: TwinState, key: string): void {
  const reciprocalKey = state[key];
  if (reciprocalKey) {
    delete state[key];
    delete state[reciprocalKey];
  }
}
