import { AbstractHandleClass } from "./AbstractHandleClass";

export class CurrentUser extends AbstractHandleClass {
  getUserId(): string {
    const id = this.getStore().auth.user?.id;
    if (id === undefined) {
      throw new Error("User id is not available");
    }
    return id;
  }

  getPrimaryAccountId(): string {
    const userId = this.getUserId();

    const accounts = this.getStore().rope_Account_User[userId] ?? [];

    const primaryAccountId = accounts[0];
    if (primaryAccountId === undefined) {
      throw new Error("Focused account id is not available");
    }
    return primaryAccountId;
  }

  getUserEmail(): string {
    const email = this.getStore().auth.user?.email;
    if (email === undefined) {
      throw new Error("User email is not available");
    }
    return email;
  }
}
