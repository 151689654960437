import { createTwin } from "~/redux/utils/createTwin";
import { setTwinRelation } from "~/redux/utils/twinUtils"; // Ensure this import is correct
import { api } from "~/redux/api.generated";

export const ChatIdSlugTwin = createTwin("chatId_slug", {
  extraReducers: (builder) =>
    builder
      // Added a matcher for the getConversationsForAccount action
      .addMatcher(
        api.endpoints.getConversationsForAccount.matchFulfilled,
        (state, action) => {
          const chatsArr = action.payload.getAccount?.chats.data ?? [];

          for (let i = 0; i < chatsArr.length; i++) {
            const chat = chatsArr[i];
            setTwinRelation(state, chat.id, chat.slug);
          }
        },
      )
      .addMatcher(api.endpoints.createChat.matchFulfilled, (state, action) => {
        const chat = action.payload.createConversation;
        setTwinRelation(state, chat.id, chat.slug);
      }),
});
