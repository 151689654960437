import { v4 } from "uuid";
import { WebSocketSubscription } from "./WebSocketSubscription";

export type EventMatcherCallback = (event: Event) => boolean;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageListenerCallback = (event: any) => void;

export interface WebSocketConnectionConstructorOptions<Context> {
  url: string;
  protocols?: string | string[];
  initializer: (ws: WebSocket) => Promise<{
    context: Context;
  }>;
  keepAliveIntervalRate?: number;
  keepAliveCallback?: (ws: WebSocket) => void;
}

export class WebSocketConnection<Context> {
  //////////////////////////////////////////////////////////////////////////////
  // PUBLIC MEMBERS
  //////////////////////////////////////////////////////////////////////////////
  addSubscription(
    subscription: WebSocketSubscription<Context>,
  ): WebSocketConnection<Context> {
    if (this.subscriptionsRegistry.has(subscription.id)) {
      console.error("Subscription already exists");
      return this;
    }
    this.subscriptionsRegistry.set(subscription.id, subscription);

    if (this._ws.readyState === this._ws.OPEN) {
      subscription.subscribe(this);
    }
    return this;
  }

  removeSubscription(
    subscription: WebSocketSubscription<Context> | { id: string },
  ): WebSocketConnection<Context> {
    if (!this.subscriptionsRegistry.has(subscription.id)) {
      throw new Error("Subscription does not exist");
    }
    const targetSubscription = this.subscriptionsRegistry.get(subscription.id);
    targetSubscription?.unsubscribe(this);
    this.subscriptionsRegistry.delete(subscription.id);
    return this;
  }

  hasSubscription(subscriptionId: string): boolean {
    return this.subscriptionsRegistry.has(subscriptionId);
  }

  public context!: Context;
  public id = v4();

  //////////////////////////////////////////////////////////////////////////////
  // The following properties are required to implement the WebSocket interface
  //////////////////////////////////////////////////////////////////////////////
  public url: string;
  public protocols: string | string[];
  public status:
    | "OPEN"
    | "CLOSED"
    //
    | "INITIALIZING"
    | "UNINITIALIZED" = "UNINITIALIZED";

  sendQueue: (string | ArrayBufferLike | Blob | ArrayBufferView)[] = [];
  sendWaiter: NodeJS.Timeout | null = null;

  public send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
    console.log("this.status", this.status);
    if (this.status !== "OPEN") {
      this.sendQueue.push(data);
      if (this.sendWaiter === null) {
        this.sendWaiter = setInterval(() => {
          if (this.status === "OPEN") {
            clearInterval(this.sendWaiter as NodeJS.Timeout);
            this.sendWaiter = null;

            for (let i = 0; i < this.sendQueue.length; i++) {
              const data = this.sendQueue[i];
              try {
                this._onMessageEvents.push(["send", data]);
                this._ws.send(data);
                this.sendQueue.splice(i, 1);
              } catch (e) {
                console.error(e);
              }
            }
          }
        }, 250);
      }
    } else {
      this._onMessageEvents.push(["send", data]);
      this._ws.send(data);
    }
  }

  public sendJson(data: Record<string, unknown>) {
    this.send(JSON.stringify(data));
  }

  constructor({
    url,
    protocols = [],
    initializer,
    keepAliveIntervalRate = 20_000,
    keepAliveCallback = (ws) => ws.send('{"event":"pusher:ping"}'),
  }: WebSocketConnectionConstructorOptions<Context>) {
    this.url = url;
    // make this.url readonly
    Object.defineProperty(this, "url", {
      value: url,
      writable: false,
    });
    this.protocols = protocols;
    // make this.protocols readonly
    Object.defineProperty(this, "protocols", {
      value: protocols,
      writable: false,
    });

    this.initializer = initializer;
    this.keepAliveIntervalRate = keepAliveIntervalRate;
    this.keepAliveCallback = keepAliveCallback;

    this.id = v4();
    this.init(this.id);
  }

  /**
   *
   * @param uniqueId this ID is used to identify the connection. Subscriptions use it to tell if it is the same as the previous connection to avoid reinitializing subscriptions on the same connection.
   * @returns
   */
  private async init(uniqueId: string) {
    this.id = uniqueId;

    if (this.status === "INITIALIZING") return;
    this.status = "INITIALIZING";
    this._ws = new WebSocket(this.url, this.protocols);

    const { context } = await this.initializer(this._ws);
    this.context = context;

    this._ws.addEventListener("message", (event) => this.handleMessage(event));
    this._ws.addEventListener("error", (event) => this.handleError(event));
    this._ws.addEventListener("close", (event) => this.handleClose(event));

    // SET UP KEEP ALIVE
    if (this.keepAliveInterval) clearInterval(this.keepAliveInterval);
    this.keepAliveInterval = setInterval(() => {
      if (this._ws.readyState === this._ws.OPEN) {
        this.keepAliveCallback(this._ws);
      }
    }, this.keepAliveIntervalRate);

    // SET UP RECONNECT
    if (this.reconnectInterval) clearInterval(this.reconnectInterval);
    this.reconnectInterval = setInterval(() => {
      if (this._ws.readyState === this._ws.CLOSED) {
        this.init(v4());
        clearInterval(this.reconnectInterval);
        clearInterval(this.keepAliveInterval);
      }
    }, 500);

    this.status = "OPEN";
    // SUBSCRIBE ANY EXISTING SUBSCRIPTIONS
    for (const [, subscription] of this.subscriptionsRegistry) {
      subscription.subscribe(this);
    }
  }

  private keepAliveInterval!: NodeJS.Timeout;
  private keepAliveIntervalRate: number;
  private keepAliveCallback: (ws: WebSocket) => void;
  private reconnectInterval!: NodeJS.Timeout;
  private initializer: WebSocketConnectionConstructorOptions<Context>["initializer"];

  private _ws!: WebSocket;
  private subscriptionsRegistry: Map<string, WebSocketSubscription<Context>> =
    new Map();

  public _onMessageEvents: Array<["send" | "receive", any]> = [];

  /** Handles messages */
  private handleMessage(event: Event) {
    this._onMessageEvents.push(["receive", (event as any).data]);
    for (const [, subscription] of this.subscriptionsRegistry) {
      subscription.onMessage(event, this);
    }
  }

  private handleError(event: Event) {
    for (const [, subscription] of this.subscriptionsRegistry) {
      subscription.onError(event, this);
    }
  }

  private handleClose(event: Event) {
    for (const [, subscription] of this.subscriptionsRegistry) {
      subscription.onClose(event, this);
    }
  }
}
