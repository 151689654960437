import { StrictMode as ReactStrictMode } from "react";
import { ReduxProvider } from "./ReduxProvider";
import { HelmetProvider } from "react-helmet-async";
import { StyleProvider } from "./StyleProvider";
import { BrowserRouter } from "react-router-dom";

import { WebSocketProvider } from "./WebSocketProvider";
import { ToastProvider } from "./ToastProvider";
import { ModalProvider } from "./ModalProvider/ModalProvider";
import { NoIndexProvider } from "./NoIndexProvider";

export function AppProvider({ children }: { children: React.ReactNode }) {
  return (
    <ReactStrictMode>
      <ReduxProvider>
        {/* typical providers */}
        <HelmetProvider>
          <NoIndexProvider>
            <StyleProvider>
              <BrowserRouter window={window}>{children}</BrowserRouter>
              {/* providers that don't need to wrap anything */}
              <ModalProvider />
              <WebSocketProvider />
              <ToastProvider />
              {/* providers that don't need to wrap anything */}
            </StyleProvider>
          </NoIndexProvider>
        </HelmetProvider>
      </ReduxProvider>
    </ReactStrictMode>
  );
}
