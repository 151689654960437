import { ID, RopesState } from "./createRope"; // Adjust the import path as needed

/**
 * Adds a bidirectional link between two entities if it doesn't already exist.
 * @param state The current state of the relationships.
 * @param sourceId The ID of the source entity.
 * @param targetId The ID of the target entity.
 */
export function addBidirectionalLink(
  state: RopesState,
  sourceId: ID,
  targetId: ID
) {
  if (!state[sourceId]) {
    state[sourceId] = [];
  }
  if (!state[targetId]) {
    state[targetId] = [];
  }
  if (!state[sourceId].includes(targetId)) {
    state[sourceId].push(targetId);
  }
  if (!state[targetId].includes(sourceId)) {
    state[targetId].push(sourceId);
  }
}

/**
 * Removes a bidirectional link between two entities if it exists.
 * @param state The current state of the relationships.
 * @param sourceId The ID of the source entity.
 * @param targetId The ID of the target entity.
 */
export function removeBidirectionalLink(
  state: RopesState,
  sourceId: ID,
  targetId: ID
) {
  state[sourceId] = state[sourceId]?.filter((id) => id !== targetId);
  state[targetId] = state[targetId]?.filter((id) => id !== sourceId);
}

/**
 * Clears all links for a given entity ID, removing any references to it from other entities as well.
 * @param state The current state of the relationships.
 * @param id The ID of the entity whose links are to be cleared.
 */
export function clearAllLinksForEntity(state: RopesState, id: ID) {
  if (state[id]) {
    state[id].forEach((relatedId) => {
      state[relatedId] = state[relatedId]?.filter(
        (idToRemove) => idToRemove !== id
      );
    });
    delete state[id];
  }
}
