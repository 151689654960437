/** @overview - Redux slice for managing notifications, including toasts and alerts. */

import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";

type NoticeType = "alert" | "banner" | "toast";

interface NoticeMessage {
  id: string;
  title: string | { [noticeType in NoticeType]: string };
  message: string | { [noticeType in NoticeType]: string };
  imageUrl?: string | null;
  types: NoticeType[];
}

interface NoticeState {
  noticeMap: Record<string, NoticeMessage>;
  activeToasts: string[];
  sentToasts: string[];
}

const initialState: NoticeState = {
  noticeMap: {},
  activeToasts: [],
  sentToasts: [],
};

export const noticeSlice = createSlice({
  name: "notice",
  initialState,
  reducers: {
    addNotice(
      state,
      action: PayloadAction<{
        id?: string;
        imageUrl?: string | null;

        title: string;
        message: string;
        types: NoticeType[];
      }>,
    ) {
      const {
        id = nanoid(),
        title,
        message,
        imageUrl = null,
        types,
      } = action.payload;
      const notice: NoticeMessage = { id, title, message, imageUrl, types };
      state.noticeMap[id] = notice; // Add new notice to noticeMap
      if (types.includes("toast")) {
        state.activeToasts.push(id); // Reference new notice by ID in activeToasts
      }
    },
    markToastAsSent(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.activeToasts.indexOf(id);
      if (index !== -1) {
        state.activeToasts.splice(index, 1); // Remove ID from activeToasts
        state.sentToasts.push(id); // Add ID to sentToasts
        // max allowed sent toasts is 30
        if (state.sentToasts.length > 30) {
          const id = state.sentToasts.shift();
          delete state.noticeMap[id!];
        }
      }
    },
  },
});

export const { addNotice, markToastAsSent } = noticeSlice.actions;
