import { detect } from "detect-browser";
import _ from "lodash";
const _browser = detect()! ?? {};

export const browser = Object.assign(_browser, {
  isIosSafari: _.isMatch(_browser, {
    os: "iOS",
    name: "ios",
  }),
});
