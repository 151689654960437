import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { cn } from "~/utils/cn";

export interface Step {
  id: string;
  name: string;
  description: string;
}

export interface StepsProgressProps {
  steps: Step[];
  currentStepIndex: number;
  onStepClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    step: Step,
    index: number,
  ) => void;
}

export function ProgressSteps({
  steps,
  currentStepIndex,
  onStepClick,
}: StepsProgressProps) {
  const getStatus = (index: number) => {
    if (index < currentStepIndex) return "complete";
    if (index === currentStepIndex) return "current";
    return "upcoming";
  };

  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <nav className="" aria-label="Progress">
        <ol
          role="list"
          className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-gray-200 dark:lg:border-gray-700"
        >
          {steps.map((step, stepIdx) => {
            const status = getStatus(stepIdx);
            const statusClasses = {
              complete:
                "bg-teal-600 text-white dark:bg-teal-500 dark:text-teal-200",
              current:
                "border-2 border-teal-600 text-teal-600 dark:border-teal-500 dark:text-teal-200",
              upcoming:
                "border-2 border-gray-300 text-gray-500 dark:border-gray-600 dark:text-gray-400",
            };

            const borderClasses =
              stepIdx === 0
                ? "rounded-t-md border-b-0"
                : stepIdx === steps.length - 1
                  ? "rounded-b-md border-t-0"
                  : "border border-gray-200 lg:border-0 dark:border-gray-700";

            const paddingClasses = stepIdx !== 0 ? "lg:pl-9" : "";

            return (
              <li key={step.id} className="relative overflow-hidden lg:flex-1">
                <div className={cn(borderClasses, "overflow-hidden")}>
                  <button
                    onClick={(e) => onStepClick?.(e, step, stepIdx)}
                    aria-current={status === "current" ? "step" : undefined}
                    className={cn(
                      "group w-full focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-teal-500 dark:focus-visible:ring-teal-400",
                      status === "complete" ? "group" : undefined,
                    )}
                  >
                    <span
                      className={cn(
                        "absolute left-0 top-0 h-full w-1 bg-transparent lg:bottom-0 lg:top-auto lg:h-1 lg:w-full",
                        {
                          "bg-teal-600 dark:bg-teal-500": status === "current",
                          "group-hover:bg-gray-200 group-focus-visible:bg-gray-200 dark:group-hover:bg-gray-600 dark:group-focus-visible:bg-gray-600":
                            status !== "current",
                        },
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={cn(
                        paddingClasses,
                        "flex items-start px-6 py-5 text-sm font-medium text-gray-700 dark:text-gray-300",
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={cn(
                            "flex h-10 w-10 items-center justify-center rounded-full",
                            statusClasses[status],
                          )}
                        >
                          {status === "complete" ? (
                            <CheckIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <span>{step.id}</span>
                          )}
                        </span>
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col text-start">
                        <span
                          className={cn(
                            "text-sm font-medium",
                            status === "upcoming"
                              ? "text-gray-500 dark:text-gray-400"
                              : "",
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>

                  {stepIdx !== 0 && (
                    <div
                      className="absolute inset-0 left-0 top-0 hidden w-3 lg:block"
                      aria-hidden="true"
                    >
                      <svg
                        className="h-full w-full text-gray-300 dark:text-gray-600"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    </div>
  );
}
