/** @overview - Manages accounts bucket. */

import _ from "lodash";
import { api } from "~/redux/api.generated";
import { AppAccount, AppSchema } from "~/redux/apiTypes";
import { createBucket } from "~/redux/utils/createBucket";
import { WEBSOCKET__ACCOUNT_UPDATED } from "~/utils/WebSocketSingleton/WebSocketConstants";

export const AccountBucket = createBucket<AppAccount, "Account", null>(
  "Account",
  {
    extraReducers: (builder) =>
      builder
        .addMatcher(api.endpoints.whoAmI.matchFulfilled, (state, action) => {
          action.payload.me.owned_accounts.map((account) => {
            const id = account.id;
            state[id] = {
              status: "fulfilled" as const,
              data: AppSchema.Account.parse(account),
              meta: null,
              error: null,
              lastUpdated: Date.now(),
            };
          });
        })
        .addMatcher(
          _.matches({ type: WEBSOCKET__ACCOUNT_UPDATED }),
          (state, action) => {
            // @ts-expect-error hahahahha
            const id = action.payload.id;
            state[id] = {
              status: "fulfilled" as const,
              // @ts-expect-error hahahahha
              data: AppSchema.Account.parse(action.payload),
              meta: null,
              error: null,
              lastUpdated: Date.now(),
            };
          },
        ),
  },
);
