import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

export function LoadableLogo({
  isAnimating: _isAnimating = false,
  duration = Math.E - 1,
  ...props
}: {
  isAnimating?: boolean;
  duration?: number;
} & React.SVGProps<SVGSVGElement>) {
  const [isAnimating, setIsAnimating] = useState(_isAnimating);

  useEffect(() => {
    setIsAnimating(_isAnimating);
  }, [_isAnimating]);

  const animateProps = (sequenceOrder: 1 | 2 | 3) => {
    const delay =
      (((sequenceOrder === 1 ? 1 : sequenceOrder === 2 ? 2 : 3) - 2.5) / 3) *
      duration;

    return {
      initial: { opacity: 1 },
      animate: isAnimating
        ? {
            opacity: [
              0, //
              0,
              0,
              0.5,
              1,
              0.9,
              0,
              0,
              0,
            ],
            transition: {
              delay,
              repeat: Infinity,
              duration,
            },
          }
        : {
            opacity: sequenceOrder === 1 ? 1 : 0,
          },
    };
  };

  return (
    <svg
      height="128"
      width="128"
      viewBox="0 0 1 30"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...props}
    >
      <motion.path
        {...animateProps(1)}
        fill="currentColor"
        d="M0.5 15 L-11.5  7 L-11.5 23 Z"
      />
      <motion.path
        {...animateProps(2)}
        fill="currentColor"
        d="M0.5 0 L-11.5  7 L0.5 15 Z"
      />
      <motion.path
        {...animateProps(3)}
        fill="currentColor"
        d="M0.5 0 L12.5 7 L0.5 15 Z"
      />
      <motion.path
        {...animateProps(1)}
        fill="currentColor"
        d="M0.5 15 L12.5 7 L12.5 23 Z"
      />
      <motion.path
        {...animateProps(2)}
        fill="currentColor"
        d="M0.5 30 L12.5 23 L0.5 15 Z"
      />
      <motion.path
        {...animateProps(3)}
        fill="currentColor"
        d="M0.5 30 L-11.5  23 L0.5 15 Z"
      />
    </svg>
  );
}
