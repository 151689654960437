import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/** The initial state for the modal management slice. */
const initialState = {
  isPaymentModalOpen: false,
};

/** Type definition for the state managed by this slice. */
export type ModalState = typeof initialState;

/** Redux slice for managing modal visibility state. */
export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    /** Sets the visibility of the payment modal. */
    setIsPaymentModalOpen(state, action: PayloadAction<boolean>) {
      state.isPaymentModalOpen = action.payload;
    },
  },
});

/** Exports actions from the modalSlice. */
export const {
  setIsPaymentModalOpen,
  //
} = modalSlice.actions;
