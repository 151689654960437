import { useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { markToastAsSent } from "~/redux/reducers/noticeSlice"; // Ensure this path is updated
import { useAppDispatch, useAppSelector } from "~/redux/store";
import { CloseButton } from "../common/Toast/CloseButton";
import { ToastContainer } from "../common/Toast/ToastContainer";
import { ToastMessage } from "../common/Toast/ToastMessage";

export function ToastProvider() {
  const dispatch = useAppDispatch();
  // Adjust the selector to get the noticeMap and activeToasts array
  const { noticeMap, activeToasts } = useAppSelector((state) => state.notice);

  useEffect(() => {
    activeToasts.forEach((toastId) => {
      const toastMsg = noticeMap[toastId];
      if (toastMsg && toastMsg.types.includes("toast")) {
        dispatch(markToastAsSent(toastMsg.id));

        toast.custom(
          (t) => {
            console.log("t", t);
            return (
              <ToastContainer key={toastMsg.id} visible={t.visible}>
                <ToastMessage
                  imageUrl={toastMsg.imageUrl}
                  title={
                    typeof toastMsg.title === "string"
                      ? toastMsg.title
                      : toastMsg.title.toast
                  }
                  message={
                    typeof toastMsg.message === "string"
                      ? toastMsg.message
                      : toastMsg.message.toast
                  }
                />
                <CloseButton
                  onClose={() => {
                    toast.dismiss(toastMsg.id);
                  }}
                />
              </ToastContainer>
            );
          },
          {
            id: toastMsg.id,
          }
        );
      }
    });
  }, [noticeMap, activeToasts, dispatch]);

  return <Toaster position="top-right" />;
}
