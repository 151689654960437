import { v4 } from "uuid";
import { WebSocketConnection } from "./WebSocketConnection";

/**
 * This class represents a particular subscription on a websocket connection
 *
 * It is consumed by the WebSocketConnection class to manage the subscriptions.
 * It is intended to be extended by a class that represents a specific subscription.
 */
export abstract class WebSocketSubscription<Context> {
  /** Subscribes sets up the subscription to start receiving messages */
  // public abstract subscribe(ws: WebSocket): void;
  public abstract subscribe(wsc: WebSocketConnection<Context>): Promise<void>;
  /** Unsubscribes terminates the subscription */
  public abstract unsubscribe(wsc: WebSocketConnection<Context>): Promise<void>;
  /**
   * Initializes the subscription, doing any necessary setup.
   *
   * @note This should be called in the subscribe method, and can be conditionally
   * omitted if subscription is already initialized & data is still relevant.
   */
  protected abstract initialize(
    wsc: WebSocketConnection<Context>,
  ): Promise<void>;

  /** Keeps track of the subscription state */
  public status:
    | "subscribed"
    | "unsubscribed"
    | "uninitialized"
    | "initializing"
    | "error" = "uninitialized";

  /** Handles messages from the websocket connection */
  public abstract onMessage(
    event: Event,
    wsc: WebSocketConnection<Context>,
  ): void;
  /** Handles errors from the websocket connection */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onError(_event: Event, wsc: WebSocketConnection<Context>): void {}
  /** Handles the websocket connection closing */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onClose(_event: Event, wsc: WebSocketConnection<Context>): void {}

  /** Do not initialize in the constructor */
  constructor(
    /** Unique identifier for the subscription */
    public id = v4(),
  ) {}
}
