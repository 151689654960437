export function ToastMessage({
  imageUrl,
  title,
  message,
}: {
  imageUrl?: string | null;
  title: string;
  message: string;
}) {
  return (
    <div className="flex-1 w-0 p-4">
      <div className="flex items-start">
        {imageUrl && (
          <div className="flex-shrink-0 pt-0.5">
            <img className="h-10 w-10 rounded-full" src={imageUrl} alt="" />
          </div>
        )}
        <div className="ml-3 flex-1">
          <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
            {title}
          </p>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
}
