import { setIsPaymentModalOpen } from "~/redux/reducers/modalSlice";
import { useAppDispatch, useAppSelector } from "~/redux/store";
import { PaymentSteps } from "~/views/common/Payment/NewPayment";
import { Dialog, DialogContent } from "~/views/common/ui/Dialog";

export function PaymentModal() {
  const isPaymentModalOpen = useAppSelector(
    (state) => state.modal.isPaymentModalOpen,
  );

  const dispatch = useAppDispatch();
  const dispatchSetIsPaymentModalOpen = (
    ...args: Parameters<typeof setIsPaymentModalOpen>
  ) => {
    return dispatch(setIsPaymentModalOpen(...args));
  };

  return (
    <Dialog
      open={isPaymentModalOpen}
      onOpenChange={dispatchSetIsPaymentModalOpen}
    >
      <PaymentDialogue
        onClickCancel={() => dispatchSetIsPaymentModalOpen(false)}
      />
    </Dialog>
  );
}

function PaymentDialogue({ onClickCancel }: { onClickCancel: () => void }) {
  return (
    <DialogContent className="h-full max-w-none p-0 lg:h-fit lg:w-[75%] xl:w-[50%]">
      <PaymentSteps onClickCancel={onClickCancel} />
    </DialogContent>
  );
}
