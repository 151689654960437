import { api } from './baseApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  DateTimeTz: { input: any; output: any; }
};

/**
 * Users have 'accounts' that they charge with credits and through which
 * all the functionality of our app is connected.
 */
export type Account = {
  __typename?: 'Account';
  account_name?: Maybe<Scalars['String']['output']>;
  /**
   * All chat threads for this account, ordered by the most recently updated one DESC
   *
   * NOTE that this will not retrieve trashed chats.
   */
  chats: ChatPaginator;
  credit_amount?: Maybe<Scalars['String']['output']>;
  /** Unique primary key. */
  id: Scalars['ID']['output'];
  /**
   * Is set to true if the account is the 'primary' account for the user that owns it.
   *
   * For each user, there will only be one 'primary' account. It will be set true automatically
   * to the account we create along with new users.
   */
  is_primary?: Maybe<Scalars['Boolean']['output']>;
  /** Root folders, for accounts, are folders that do not have */
  root_folders: FolderPaginator;
  /** Gets all the chats currently marked as 'trashed' in our system. */
  trash_binned_chats: ChatPaginator;
};


/**
 * Users have 'accounts' that they charge with credits and through which
 * all the functionality of our app is connected.
 */
export type AccountChatsArgs = {
  first: Scalars['Int']['input'];
  order_by?: InputMaybe<Array<AccountChatsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Users have 'accounts' that they charge with credits and through which
 * all the functionality of our app is connected.
 */
export type AccountRoot_FoldersArgs = {
  first: Scalars['Int']['input'];
  order_by?: InputMaybe<Array<AccountRootFoldersOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * Users have 'accounts' that they charge with credits and through which
 * all the functionality of our app is connected.
 */
export type AccountTrash_Binned_ChatsArgs = {
  first: Scalars['Int']['input'];
  order_by?: InputMaybe<Array<AccountTrashBinnedChatsOrderByOrderByClause>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Allowed column names for Account.chats.order_by. */
export enum AccountChatsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Account.chats.order_by. */
export type AccountChatsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: AccountChatsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Account.root_folders.order_by. */
export enum AccountRootFoldersOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Account.root_folders.order_by. */
export type AccountRootFoldersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: AccountRootFoldersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for Account.trash_binned_chats.order_by. */
export enum AccountTrashBinnedChatsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Account.trash_binned_chats.order_by. */
export type AccountTrashBinnedChatsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: AccountTrashBinnedChatsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  token?: Maybe<Scalars['String']['output']>;
};

export enum Chat_Message_Source {
  Bot = 'BOT',
  User = 'USER'
}

export enum Chat_Message_Status {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  Failed = 'FAILED',
  Queued = 'QUEUED'
}

/**
 * 'Chats' store text completion, audio, and image generation chats that interface with models.
 * Under chats are ChatMessages, which store individual messages.
 *
 * for now, we're not sending back user or account information on this object due to security concerns.
 * you can go one way from accounts -> chats.
 */
export type Chat = {
  __typename?: 'Chat';
  /** All the update records for these chats. */
  chat_history_records: Array<Maybe<ChatHistoryRecord>>;
  chat_messages: ChatMessagePaginator;
  /** When the account was created. */
  created_at: Scalars['DateTimeTz']['output'];
  /** The chronologically first chat message made to this chat thread. */
  first_chat_message?: Maybe<ChatMessage>;
  /** Gets the first (aka: oldest) message marked as being on the golden path. */
  first_golden_message?: Maybe<ChatMessage>;
  /**
   * A nullable object representing the folder this chat is a part of. This can be null, since
   * chats don't need a folder assignment.
   */
  folder?: Maybe<Folder>;
  /**
   * An array of all the 'golden path' messages under this chat, in order.
   *
   * Use the pagination options in this query to get the 'total' number of messages.
   */
  golden_path_messages: ChatMessagePaginator;
  /** Unique primary key. */
  id: Scalars['ID']['output'];
  /**
   * Checks if the chat in question is a 'favorite' for the given user.
   * Keep in mind that this has slightly higher query complexity, so we'll be sure to monitor its performance
   * on large chat queries.
   */
  is_favorite?: Maybe<Scalars['Boolean']['output']>;
  /** The most recent chat sorted by created_at date. */
  most_recent_chat_message?: Maybe<ChatMessage>;
  /** Gets the newest message on the golden path, (its "tail") */
  most_recent_golden_message?: Maybe<ChatMessage>;
  name?: Maybe<Scalars['String']['output']>;
  /**
   * future feature, we'll allow users to add a prompt to each message they send as context.
   * does nothing for now.
   */
  prompt?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  /**
   * If a chat is in the trash bin, it is effectively marked for deletion and will be permanently deleted
   * eventually.
   *
   * This means it should be taken out of the normal UI listing chats and go to our 'trash bin' ui.
   *
   * We're storing this trash_binned_at as a date because we're not
   */
  trash_binned_at?: Maybe<Scalars['DateTimeTz']['output']>;
  /** When the account was last updated. */
  updated_at: Scalars['DateTimeTz']['output'];
  /** The user who last updated/created this chat message. */
  updatingUser?: Maybe<User>;
};


/**
 * 'Chats' store text completion, audio, and image generation chats that interface with models.
 * Under chats are ChatMessages, which store individual messages.
 *
 * for now, we're not sending back user or account information on this object due to security concerns.
 * you can go one way from accounts -> chats.
 */
export type ChatChat_MessagesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


/**
 * 'Chats' store text completion, audio, and image generation chats that interface with models.
 * Under chats are ChatMessages, which store individual messages.
 *
 * for now, we're not sending back user or account information on this object due to security concerns.
 * you can go one way from accounts -> chats.
 */
export type ChatGolden_Path_MessagesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * These are our default completion options for chat completions.
 * these will all be set up with defaults if none are supplied
 *
 * Be sure to ask me about these as I'll be implementing them slowly as we go through.
 */
export type ChatCompletionOptions = {
  frequency_penalty?: InputMaybe<Scalars['Int']['input']>;
  /**
   * determine the total tokens, sent and received, that we're willing to accept for the model.
   * if we send 100 tokens, and we say max_tokens = 150, that means that we're leaving 50 tokens for the response.
   */
  max_tokens?: InputMaybe<Scalars['Int']['input']>;
  presence_penalty?: InputMaybe<Scalars['Int']['input']>;
  repetition_penalty?: InputMaybe<Scalars['Int']['input']>;
  seed?: InputMaybe<Scalars['Int']['input']>;
  /**
   * 'stop' strings are strings that will tell the LLM to end generation on the spot.
   *
   * example ['2.'] might be used to stop the LLM on the second bullet point of a returned list.
   */
  stop?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /**
   * note that this isn't enabled yet. It won't do anything until we enable streaming in the future.
   * We still might want to talk about how we'll implement this technically.
   */
  stream?: InputMaybe<Scalars['Boolean']['input']>;
  temperature?: InputMaybe<Scalars['Int']['input']>;
  top_k?: InputMaybe<Scalars['Int']['input']>;
  top_p?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Records a chat history, with the name, prompt, and the user that update that name and prompt.
 *
 * These histories are created as new data is updated to the chat.
 */
export type ChatHistoryRecord = {
  __typename?: 'ChatHistoryRecord';
  created_at?: Maybe<Scalars['DateTimeTz']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTimeTz']['output']>;
  /** The user responsible for this version of the chat. */
  user?: Maybe<User>;
};

/** NOTE that this taxonomy will be deprecated soon in favor of Message */
export type ChatMessage = {
  __typename?: 'ChatMessage';
  /** The parent thread of the chat this is message is a part of. */
  chat: Chat;
  /**
   * displays all the messages that show this message as 'parent'
   *
   * I doubt this'll get big but I'm paginating it *just in case* someone gets nutty remakes.
   */
  child_messages: ChatMessagePaginator;
  /**
   * Sometimes messages can be chained together in 'continuations'
   *
   * Think for example about us plugging in a DALL-E function to chat gpt chats like we talked about, where
   * we have the response function-call and then a separate DALL-E result.
   *
   * if the current message is a 'continuation', this shows its parent. These are meant to be shown
   * as a single response to users.
   */
  continuation_of_message_parent?: Maybe<ChatMessage>;
  /** Returns the 'child' continuing message for this message, if one exists. The counterpart to continuation_of_message_parent */
  continuing_message_child?: Maybe<ChatMessage>;
  created_at?: Maybe<Scalars['DateTimeTz']['output']>;
  /**
   * The key component of our 'golden path' capability, the 'favorite child' is the one that should
   * be shown as the default to users when there are multiple child_responses to a message.
   */
  favorite_child?: Maybe<ChatMessage>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  /**
   * The 'transactions' we record for messages. This includes costs as well as the status of the transaction.
   *
   * This is where you'll look to find if the message in question has a 'pending' or 'queued' transaction in the works.
   */
  message_transactions: Array<Maybe<MessageTransaction>>;
  /**
   * the 'parent' message of this message. This is the backbone of our relational message-tree structure,
   * where you can start at the most recent member of the 'golden path' and traverse up the tree.
   */
  parent_message?: Maybe<ChatMessage>;
  /** Returns any messages that are 'resends' of this message. */
  resent_message_children: ChatMessagePaginator;
  /**
   * if this message is a 'resend', this relation will show the original chat that this resend
   * was generated from. 'resends' are the ones you wanted to be invisible in the UI
   */
  resent_message_parent?: Maybe<ChatMessage>;
  /** the source of the message, or who sent it essentially. the source of a message can be from a bot or a user. */
  source?: Maybe<Chat_Message_Source>;
  updated_at?: Maybe<Scalars['DateTimeTz']['output']>;
};


/** NOTE that this taxonomy will be deprecated soon in favor of Message */
export type ChatMessageChild_MessagesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


/** NOTE that this taxonomy will be deprecated soon in favor of Message */
export type ChatMessageResent_Message_ChildrenArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ChatMessageInput = {
  continuation_of_message_parent_id?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  parent_message_id?: InputMaybe<Scalars['String']['input']>;
  resend_for_message_id?: InputMaybe<Scalars['String']['input']>;
};

/** A paginated list of ChatMessage items. */
export type ChatMessagePaginator = {
  __typename?: 'ChatMessagePaginator';
  /** A list of ChatMessage items. */
  data: Array<ChatMessage>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** A paginated list of Chat items. */
export type ChatPaginator = {
  __typename?: 'ChatPaginator';
  /** A list of Chat items. */
  data: Array<Chat>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  client_secret: Scalars['String']['output'];
};

export type CreateChatCompletionTextMessageInput = {
  chat_message_input: ChatMessageInput;
  chat_options: ChatCompletionOptions;
};

export type CreateIntentToPurchaseCreditsInput = {
  account_id: Scalars['String']['input'];
  credit_product_id: Scalars['String']['input'];
  unit_quantity: Scalars['Int']['input'];
};

export type CreateSetupIntentForPaymentMethod = {
  account_id: Scalars['String']['input'];
};

export type CreateSubscriptionCheckoutSessionInput = {
  account_id: Scalars['String']['input'];
  /**
   * Stripe 'products' have many 'prices' on them. For example, we have different 'prices' for
   * monthly and yearly subscriptions, even though the subscription itself is the same 'product'.
   */
  price_id: Scalars['String']['input'];
};

export type CreateSubscriptionWithPaymentMethodInput = {
  account_id: Scalars['String']['input'];
  payment_method_id: Scalars['String']['input'];
  price_id: Scalars['String']['input'];
};

/**
 * A "Credit Product" is a bundle of credits that a user can buy for a given price.
 *
 * for now, we're just concerned with regular_price, but in the future we can add subscriber and discount prices.
 *
 * Users purchase these Credit Products via our payment intents API.
 *
 * prices are displayed in 'cents' integers, as well as fully formatted
 */
export type CreditProduct = {
  __typename?: 'CreditProduct';
  /** The number of credits the user will receive when they pay the given price. */
  credit_amount?: Maybe<Scalars['String']['output']>;
  discount_price_cents?: Maybe<Scalars['Int']['output']>;
  discount_price_cents_formatted?: Maybe<Scalars['String']['output']>;
  /** Unique primary key. */
  id: Scalars['ID']['output'];
  /**
   * Determines whether or not we want to show this to users.
   *
   * I'm debating whether or not I want to send these to the frontend at all, but
   * nonetheless it'd be good to add filter logic to make sure the user never sees this.
   */
  is_active?: Maybe<Scalars['Boolean']['output']>;
  /** Our custom name for the product. We'll make catchy names or something. */
  name?: Maybe<Scalars['String']['output']>;
  /** for now just focus on this price over the others. */
  regular_price_cents: Scalars['Int']['output'];
  /** Purdy formatting */
  regular_price_formatted: Scalars['String']['output'];
  subscriber_price_cents?: Maybe<Scalars['Int']['output']>;
  subscriber_price_formatted?: Maybe<Scalars['String']['output']>;
  /**
   * Determines if we only offer this deal to subscribers.
   * Might be nice to show to non-subscribed users so they can see what they're missing out on?
   */
  subscription_only?: Maybe<Scalars['Boolean']['output']>;
};

/** contains all the messages for a particular conversation */
export type DemoConversation = {
  __typename?: 'DemoConversation';
  demo_messages: Array<DemoMessage>;
  id: Scalars['ID']['output'];
};

/** represents a single message either from the 'bot' or from the 'user'. */
export type DemoMessage = {
  __typename?: 'DemoMessage';
  /** use this field to find all the messages in the existing conversation. */
  demo_conversation?: Maybe<DemoConversation>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  /** source can be one of 'bot' or 'user' */
  source: Scalars['String']['output'];
};

export type Folder = {
  __typename?: 'Folder';
  account: Account;
  chats: Array<Maybe<Chat>>;
  created_at: Scalars['DateTimeTz']['output'];
  /** @deprecated No longer supported */
  creating_user: User;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent_folder?: Maybe<Folder>;
  sub_folders: Array<Maybe<Folder>>;
};


export type FolderChatsArgs = {
  order_by?: InputMaybe<Array<FolderChatsOrderByOrderByClause>>;
};


export type FolderSub_FoldersArgs = {
  order_by?: InputMaybe<Array<FolderSubFoldersOrderByOrderByClause>>;
};

/** Allowed column names for Folder.chats.order_by. */
export enum FolderChatsOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Folder.chats.order_by. */
export type FolderChatsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: FolderChatsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type FolderInput = {
  name: Scalars['String']['input'];
};

/** A paginated list of Folder items. */
export type FolderPaginator = {
  __typename?: 'FolderPaginator';
  /** A list of Folder items. */
  data: Array<Folder>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

/** Allowed column names for Folder.sub_folders.order_by. */
export enum FolderSubFoldersOrderByColumn {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT'
}

/** Order by clause for Folder.sub_folders.order_by. */
export type FolderSubFoldersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: FolderSubFoldersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

export type LoginInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type Message = {
  __typename?: 'Message';
  /** The parent thread of the chat this is message is a part of. */
  chat: Chat;
  /**
   * displays all the messages that show this message as 'parent'
   *
   * I doubt this'll get big but I'm paginating it *just in case* someone gets nutty remakes.
   */
  child_messages: ChatMessagePaginator;
  /**
   * Sometimes messages can be chained together in 'continuations'
   *
   * Think for example about us plugging in a DALL-E function to chat gpt chats like we talked about, where
   * we have the response function-call and then a separate DALL-E result.
   *
   * if the current message is a 'continuation', this shows its parent. These are meant to be shown
   * as a single response to users.
   */
  continuation_of_message_parent?: Maybe<ChatMessage>;
  /** Returns the 'child' continuing message for this message, if one exists. The counterpart to continuation_of_message_parent */
  continuing_message_child?: Maybe<ChatMessage>;
  created_at?: Maybe<Scalars['DateTimeTz']['output']>;
  /**
   * decimal cost of the message in credits.
   * This decimal will typically be very very small.
   */
  credit_cost?: Maybe<Scalars['String']['output']>;
  /**
   * The key component of our 'golden path' capability, the 'favorite child' is the one that should
   * be shown as the default to users when there are multiple child_responses to a message.
   */
  favorite_child?: Maybe<ChatMessage>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  /**
   * Determines what LLM this message was sent to/received from.
   * Read more about model definitions on the object itself.
   */
  model_definition?: Maybe<ModelDefinition>;
  /**
   * the 'parent' message of this message. This is the backbone of our relational message-tree structure,
   * where you can start at the most recent member of the 'golden path' and traverse up the tree.
   */
  parent_message?: Maybe<ChatMessage>;
  /** Returns any messages that are 'resends' of this message. */
  resent_message_children: ChatMessagePaginator;
  /**
   * if this message is a 'resend', this relation will show the original chat that this resend
   * was generated from. 'resends' are the ones you wanted to be invisible in the UI
   */
  resent_message_parent?: Maybe<ChatMessage>;
  /** the source of the message, or who sent it essentially. the source of a message can be from a bot or a user. */
  source?: Maybe<Chat_Message_Source>;
  status: Chat_Message_Status;
  /**
   * System status messages to give more context when necessary. These messages 'frontend safe' and can be shown to users.
   * This will only be populated when the system determines that the 'status' code is not sufficient to explain what happened.
   */
  status_message?: Maybe<Scalars['String']['output']>;
  /**
   * the total cost in 'units' for this request.
   * 'units' will typically mean 'tokens' but we're keeping it
   * agnostic in the event we integrate with a non-conforming LLM in the future.
   */
  unit_cost?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['DateTimeTz']['output']>;
};


export type MessageChild_MessagesArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type MessageResent_Message_ChildrenArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Represents a single time we sent this message to an LLM.
 * We intend to open up 'resend' functionality, so we want
 */
export type MessageTransaction = {
  __typename?: 'MessageTransaction';
  id: Scalars['ID']['output'];
  model_definition?: Maybe<ModelDefinition>;
  recorded_model_credit_receive_cost_per_unit?: Maybe<Scalars['Int']['output']>;
  recorded_model_credit_send_cost_per_unit?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<MessageTransactionStatus>;
};

export enum MessageTransactionStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Queued = 'QUEUED'
}

/**
 * for each of the LLM models we support, 'gpt4, gemini, etc' we represent
 * those models in a ModelDefinition object.
 */
export type ModelDefinition = {
  __typename?: 'ModelDefinition';
  /**
   * If we've hit rate limits on this model, this field tells us when the system will be open again for use.
   * If it's not null, we're currently not being rate limited.
   */
  backoff_resolution?: Maybe<Scalars['DateTimeTz']['output']>;
  /**
   * This tells the system what type of chat we're running
   * openAI and other systems have different APIs for different types
   * of messages, ex: image generation APIs are different from chat text completion APIs
   *
   * Note in the future, we'll modify some 'text' models to ALSO handle images and speech automatically through backend
   * magic.
   *
   * We use this value on our backend to figure out what APIs we can call, and on the frontend this will determine what graphql functions you'll call.
   *
   * for now we just support 'text'
   */
  chat_type?: Maybe<ModelDefinitionChatTypeEnum>;
  /** see credit_send_cost_per_unit */
  credit_receive_cost_per_unit: Scalars['String']['output'];
  /**
   * The credit cost per unit values will be fractions expressed as strings.
   *
   * We use strings to represent the decimals as the Float type cannot accurately
   * store floats with the precision we want.
   */
  credit_send_cost_per_unit: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  /** determines if we're currently allowing this model to be used in new requests */
  is_active: Scalars['Boolean']['output'];
  max_requests_day?: Maybe<Scalars['Int']['output']>;
  max_requests_hour?: Maybe<Scalars['Int']['output']>;
  max_requests_min?: Maybe<Scalars['Int']['output']>;
  max_requests_month?: Maybe<Scalars['Int']['output']>;
  max_requests_second?: Maybe<Scalars['Int']['output']>;
  max_tokens_day?: Maybe<Scalars['Int']['output']>;
  max_tokens_hour?: Maybe<Scalars['Int']['output']>;
  max_tokens_min?: Maybe<Scalars['Int']['output']>;
  max_tokens_month?: Maybe<Scalars['Int']['output']>;
  /** most models have a limit to the amount of tokens they can consume. */
  max_tokens_per_request?: Maybe<Scalars['Int']['output']>;
  max_tokens_second?: Maybe<Scalars['Int']['output']>;
  /**
   * This helps us determine who we're reaching out to for this service.
   * This might be good on your end as well to show users where
   * their chat it being sent off to.
   *
   * for now we just support 'openai'
   */
  module?: Maybe<ModelDefinitionModuleEnum>;
  official_name: Scalars['String']['output'];
  public_name: Scalars['String']['output'];
  /**
   * when we start supporting files, we'll define what extensions are valid here.
   * for now we don't support files so we'll flesh this out later.
   */
  uploadable_file_types: Array<Maybe<Scalars['String']['output']>>;
};

export enum ModelDefinitionChatTypeEnum {
  ImageGeneration = 'IMAGE_GENERATION',
  SpeechToText = 'SPEECH_TO_TEXT',
  Text = 'TEXT',
  TextToSpeech = 'TEXT_TO_SPEECH'
}

export enum ModelDefinitionModuleEnum {
  Custom = 'CUSTOM',
  Emulator = 'EMULATOR',
  Google = 'GOOGLE',
  Mistral = 'MISTRAL',
  Openai = 'OPENAI'
}

/** A paginated list of ModelDefinition items. */
export type ModelDefinitionPaginator = {
  __typename?: 'ModelDefinitionPaginator';
  /** A list of ModelDefinition items. */
  data: Array<ModelDefinition>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Given a chat, it adds the chat to a folder. Chats can only be assigned to one folder at a time.
   *
   * Pass a 'null' string to make the chat 'folderless'
   *
   * Keep in mind that you need permissions for both the folder and the chat itself.
   *
   * Also, the given chat needs to have the same account as the folder does
   */
  assignChatToFolder?: Maybe<Chat>;
  createChatCompletionTextMessage?: Maybe<ChatMessage>;
  /**
   * This creates one 'conversation' for a given account.
   * 'Conversation' is the new word for Chat.
   * We're rolling out the new taxonomy slowly so for now the 'createConversation' item is returning a 'Chat' obj.
   *
   * the owning_account_id must be in turn owned by you, the authenticated user. it will
   * be the account under which the 'conversation' is placed
   */
  createConversation: Chat;
  /**
   * When creating a folder, you can specify a 'parent' that it goes under.
   * This parent must share the same account as the one you're assigning to the new folder.
   *
   * Also, we don't allow folders more than 8 deep.
   *
   * The system will also detect if a recursive relationship between folders is being made and throw an exception.
   */
  createFolder?: Maybe<Folder>;
  /**
   * When we purchase credits, we purchase credits that will be added to a user's Account.
   * The account passed must be owned by the user.
   *
   * Unit_quantity tells us how much of the given credit_product a user would like to buy.
   * For example, Buying 2 of a 10 dollars for 10 credits deal would create a charge of 20 dollars for 20 credits.
   *
   * What you really care about here is the client_secret, which you kick over to your frontend stripe JS implementation.
   */
  createIntentToPurchaseCredits: PaymentIntent;
  /** Creates a Setup Intent, which can be used to make a new payment method record in stripe that can then be applied to subscriptions and one time purchases. */
  createSetupIntentForPaymentMethod: StripeSetupIntent;
  /**
   * Creates a 'Checkout Session' for a subscription.
   *
   * The response string is a subscription client_secret_key that can be plugged into a Web Element component
   * to complete the transaction.
   *
   * See this documentation on stripe's end for more details: https://docs.stripe.com/checkout/embedded/quickstart
   *
   * After you use/call this function, the backend will update with the successful subscription asynchronously.
   */
  createSubscriptionCheckoutSession: CheckoutSession;
  /**
   * Creates a new subscription for the user with the specified price.
   * Unlike createSubscriptionCheckoutSession, which creates an intent that you then fulfull on the frontend, this
   * uses the provided payment method ID and actually creates the new subscription from the backend, so by the time
   * this returns the Account to you it'll have its new subscription all attached and set up.
   */
  createSubscriptionWithPaymentMethod?: Maybe<Account>;
  /**
   * Using a temporary token that you will have been given by the backend at the end of an oauth workflow. What you'll get in return is an
   * auth token identical to one that you'd get off login that can authenticate the user to the site.
   *
   * This is implemented as a mutation because its purpose is similar to 'login' and login is a mutation. Logins are also implemented as 'POST' requests
   * in normal REST and POST is typically mutation-esque.
   */
  exchangeTempOauthTokenForAuthToken?: Maybe<AuthToken>;
  /** The token response from this needs to be included as an Authorization header with Bearer <token> format */
  login?: Maybe<AuthToken>;
  /**
   * Adds the given chat to your personal favorites. Checks that you have view permission for the conversation in question.
   * returns a placeholder boolean that should be thought of as a void return. If something goes wrong you'll know from the exceptions we throw.
   */
  markConversationAsFavorite?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Consumes a message ID and looks to its 'effective parent'
   *
   * If the message HAS a parent and you're authorized to modify both the message in question and the parent,
   * it will assign message as the 'favorite' child.
   *
   * This will also kickoff the process to remake the 'golden path' synchronously.
   *
   * NOTE: golden path changes won't kick off websocket events foreach message on the golden path
   */
  markMessageAsFavorite: Message;
  /**
   * Part of our 'Trash Binned' capability.
   * Will set the 'trashed_binned_at' field to null.
   *
   * see the trash_binned field for what it means and its implications.
   */
  removeChatFromTrashBin?: Maybe<Chat>;
  /**
   * Part of our 'Trash Binned' capability.
   * Will set the 'trashed_binned_at' field to the current date.
   *
   * see the trash_binned field for what it means and its implications.
   */
  sendChatToTrashBin?: Maybe<Chat>;
  /**
   * Used for our demo message feature for unauthenticated users.
   *
   * the demo_conversation_id should be a uuidv6 string, and the message cannot exceed 100 characters (let me know if you'd like this increased)
   *
   * sends a demo message using one of our cheaper models. Unlike our other chat workflow this occurs synchronously, so the system will resolve with
   * a demo message from the llm.
   *
   * the demo_conversation_id allows you to keep a context message history, so be sure to send the same one each time.
   *
   * The system is currently programmed to not allow conversations to exceed 8 messages total for security/rate limiting reasons.
   *
   * Also note that we're throttling this to 4 requests/min per IP at the moment. We'll make a more intelligent rate limiter in the future.
   *
   * This is protected by google captcha and requires a Recaptcha-Temp-Key header in the request.
   */
  sendDemoMessage?: Maybe<DemoMessage>;
  signUp?: Maybe<User>;
  /**
   * see markConversationAsFavorite
   * removes the relationship between yourself and a given chat.
   * If you don't have a relationship currently, it just does nothing and returns success
   * Boolean response is effectively a void placeholder. Will throw Not Fround and Auth exceptions.
   */
  unfavoriteConversation?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated This was the user update mutation. obviously super ambiguous. Deprecated in favor of the updateUser function */
  update?: Maybe<User>;
  /**
   * you can only update accounts that you 'own' via the owned_accounts user relation at the moment.
   *
   * Also, we've limited the update capability to just the name for now as well
   *
   * We'll add more to this endpoint in the future when accounts become more complex.
   */
  updateAccount?: Maybe<Account>;
  /**
   * Updates a given conversation's name and prompt. Authorizes that the user in question owns the account
   * conversation. For now it's a simple name and prompt CRUD update.
   */
  updateConversation: Chat;
  /**
   * When creating a folder, you can specify a 'parent' to move it to.
   * if you don't specify a parent, the folder will reverto to being a 'root folder' for the account.
   * This parent must share the same account as the current folder..
   *
   * We don't allow folders more than 8 deep.
   *
   * The system will also detect if a recursive relationship between folders is being made and throw an exception.
   */
  updateFolder?: Maybe<Folder>;
  /**
   * We always assume that the intended target of the update is you, the currently logged in user. As such, an ID is not necessary.
   * There's no use case (yet) for updating other users.
   */
  updateUser?: Maybe<User>;
};


export type MutationAssignChatToFolderArgs = {
  chat_id: Scalars['String']['input'];
  folder_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateChatCompletionTextMessageArgs = {
  account_id: Scalars['String']['input'];
  chat_id: Scalars['String']['input'];
  input?: InputMaybe<CreateChatCompletionTextMessageInput>;
  model_definition_id: Scalars['String']['input'];
};


export type MutationCreateConversationArgs = {
  input?: InputMaybe<UpsertConversationInput>;
  owning_account_id: Scalars['String']['input'];
};


export type MutationCreateFolderArgs = {
  account_id: Scalars['String']['input'];
  input: FolderInput;
  parent_folder_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateIntentToPurchaseCreditsArgs = {
  input?: InputMaybe<CreateIntentToPurchaseCreditsInput>;
};


export type MutationCreateSetupIntentForPaymentMethodArgs = {
  input?: InputMaybe<CreateSetupIntentForPaymentMethod>;
};


export type MutationCreateSubscriptionCheckoutSessionArgs = {
  input?: InputMaybe<CreateSubscriptionCheckoutSessionInput>;
};


export type MutationCreateSubscriptionWithPaymentMethodArgs = {
  input?: InputMaybe<CreateSubscriptionWithPaymentMethodInput>;
};


export type MutationExchangeTempOauthTokenForAuthTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type MutationMarkConversationAsFavoriteArgs = {
  conversation_id: Scalars['String']['input'];
};


export type MutationMarkMessageAsFavoriteArgs = {
  message_id: Scalars['String']['input'];
};


export type MutationRemoveChatFromTrashBinArgs = {
  chat_id: Scalars['String']['input'];
};


export type MutationSendChatToTrashBinArgs = {
  chat_id: Scalars['String']['input'];
};


export type MutationSendDemoMessageArgs = {
  demo_conversation_id: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSignUpArgs = {
  input?: InputMaybe<SignUpInput>;
};


export type MutationUnfavoriteConversationArgs = {
  conversation_id: Scalars['String']['input'];
};


export type MutationUpdateArgs = {
  input?: InputMaybe<UpdateUserInput>;
};


export type MutationUpdateAccountArgs = {
  account_id: Scalars['String']['input'];
  input?: InputMaybe<UpdateAccountInput>;
};


export type MutationUpdateConversationArgs = {
  conversation_id: Scalars['String']['input'];
  input?: InputMaybe<UpsertConversationInput>;
};


export type MutationUpdateFolderArgs = {
  folder_id: Scalars['String']['input'];
  input: FolderInput;
  parent_folder_id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  /**
   * The amount stripe tells us was paid into the intent.
   * Stripe will tell us this on the backend via our API webhooks.
   *
   * When you first create a Payment Intent, this will be 0 since the user hasn't paid yet.
   */
  amount_received_cents?: Maybe<Scalars['Int']['output']>;
  amount_received_formatted?: Maybe<Scalars['String']['output']>;
  /** The amount we determine they owe, NOT what they've paid yet. */
  charged_amount_cents: Scalars['Int']['output'];
  /** Purdy formatting */
  charged_amount_formatted: Scalars['String']['output'];
  /**
   * Plug this field into the frontend Stripe payment workflow.
   *
   * Stripe knows that this key is associated with the payment ID we want.
   */
  client_secret: Scalars['String']['output'];
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['ID']['output'];
  /**
   * Stripe has many intricate messages relating to the specific status of a payment intent.
   * We condense it a bit to what we're interested in. It's either completed, or not.
   */
  status?: Maybe<PaymentIntentStatusEnum>;
  stripe_id: Scalars['String']['output'];
};

export enum PaymentIntentStatusEnum {
  Completed = 'COMPLETED',
  NotCompleted = 'NOT_COMPLETED'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /**
   * This will only be present if the payment method is a card. Stripe supports a massive number of payment methods, all with their own custom fields.
   *
   * These can be determined by the 'type' of payment method.
   */
  card?: Maybe<PaymentMethodCard>;
  created_at: Scalars['DateTimeTz']['output'];
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand?: Maybe<Scalars['String']['output']>;
  exp_month?: Maybe<Scalars['String']['output']>;
  exp_year?: Maybe<Scalars['String']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getAccount?: Maybe<Account>;
  /** Gets all active model definitions we store in the system. */
  getActiveModelDefinitions: ModelDefinitionPaginator;
  /**
   * Gets all active subscription products.
   *
   * You can use the provided filter to search for organization and personal subscription products.
   */
  getActiveSubscriptionProducts: Array<Maybe<SubscriptionProduct>>;
  getChatMessage: ChatMessage;
  /**
   * Gets a conversation by EITHER its uuid (id to you) or its slug.
   * Throws the typical not found exception if the provided string matches neither slug nor uuid
   * Authorizes the user, such that only users with 'view' permissions for the conversation can access it.
   */
  getConversation: Chat;
  /**
   * Purchasing these products adds credits to the user's account.
   * We'll add more granular filters as needed. For now we just return all of them.
   */
  getCreditProducts: Array<Maybe<CreditProduct>>;
  /** Simple query to retrieve a folder by its provided ID, user is authenticated for folder ownership. */
  getFolder?: Maybe<Folder>;
  /** Gets a series of payment methods that the account is allowed to use for subscriptions */
  getPaymentMethodsForAccount: Array<Maybe<PaymentMethod>>;
  me: User;
  /** searches for chats using a trigram matching algorithm */
  searchChats?: Maybe<Array<Maybe<Chat>>>;
  /**
   * For a given message id, get an array of its children traversed down to a 'count' amount.
   * The max count is capped at 500, let me know if we'd like to expand it.
   */
  traverseDownMessageTree: Array<Maybe<ChatMessage>>;
  /**
   * For a given message id, get an array of its parents up to the 'count' provided.
   * The max count is capped at 500, let me know if we'd like to expand it.
   */
  traverseUpMessageTree: Array<Maybe<ChatMessage>>;
};


export type QueryGetAccountArgs = {
  account_id: Scalars['String']['input'];
};


export type QueryGetActiveModelDefinitionsArgs = {
  first: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetActiveSubscriptionProductsArgs = {
  is_organization?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetChatMessageArgs = {
  chat_message_id: Scalars['String']['input'];
};


export type QueryGetConversationArgs = {
  conversation_id: Scalars['String']['input'];
};


export type QueryGetFolderArgs = {
  folder_id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPaymentMethodsForAccountArgs = {
  account_id: Scalars['String']['input'];
};


export type QuerySearchChatsArgs = {
  account_id: Scalars['String']['input'];
  search_string: Scalars['String']['input'];
};


export type QueryTraverseDownMessageTreeArgs = {
  count: Scalars['Int']['input'];
  message_id: Scalars['String']['input'];
};


export type QueryTraverseUpMessageTreeArgs = {
  count: Scalars['Int']['input'];
  message_id: Scalars['String']['input'];
};

export type SignUpInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  family_name?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Setup Intents are used to capture and store Payment Methods for future use. */
export type StripeSetupIntent = {
  __typename?: 'StripeSetupIntent';
  client_secret: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: StripeSetupIntentStatusEnum;
};

export enum StripeSetupIntentStatusEnum {
  Cancelled = 'CANCELLED',
  Processing = 'PROCESSING',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresConfirmation = 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  Succeeded = 'SUCCEEDED'
}

export type Subscription = {
  __typename?: 'Subscription';
  accountUpdated?: Maybe<Account>;
  /**
   * subscribes to all chatMessages that you either own directly OR that are in a conversation you are a part of.
   *
   * note that while we say 'updated' here, you'll also get newly created chatMessages as well.
   */
  chatMessageUpdated?: Maybe<ChatMessage>;
};


export type SubscriptionAccountUpdatedArgs = {
  id: Scalars['ID']['input'];
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** if true, this plan cannot be applied to 'personal' accounts and vice-versa */
  is_organization: Scalars['Boolean']['output'];
  /** The maximum number of users that may be added as account members per the plan. */
  max_account_users: Scalars['Int']['output'];
  /**
   * Float represented as a stringshowing the total credits that this plan will
   * give to the plan monthly.
   * It will typically be a whole number but we have it here as a decimal to be consistent with credit storage.
   */
  monthly_credit_allowance: Scalars['String']['output'];
  /** displayable name of the subscription plan */
  name?: Maybe<Scalars['String']['output']>;
  /** This maps to 'product_id' field found on stripe products. */
  stripe_id: Scalars['String']['output'];
  /** If the account is eligible for a trial period, this is the number of days that will be applied. */
  trial_period_days: Scalars['Int']['output'];
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdateAccountInput = {
  account_name: Scalars['String']['input'];
};

export type UpdateUserInput = {
  family_name?: InputMaybe<Scalars['String']['input']>;
  given_name?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertConversationInput = {
  /**
   * The name of the conversation. Not currently enforced to be unique.
   * In the future we'll make this nullable and let the system decide it for us
   * via that mini autocomplete feature.
   *
   * MAX 40 characters at the moment. Wanna keep these short.
   */
  name?: InputMaybe<Scalars['String']['input']>;
  /**
   * Not currently being used, but if the user wanted to add a prompt
   * that would be added before ALL messages as a 'system' prompt to the LLMs.
   * I think we're planning on integrating the backend for this sometime in 1.5
   *
   * MAX 1000 characters, though we can go higher if you think that's necessary.
   */
  prompt?: InputMaybe<Scalars['String']['input']>;
};

/** Account of a person who utilizes this application. */
export type User = {
  __typename?: 'User';
  /** When the account was created. */
  created_at: Scalars['DateTime']['output'];
  /** Unique email address. */
  email: Scalars['String']['output'];
  /** When the email was verified. */
  email_verified_at?: Maybe<Scalars['DateTime']['output']>;
  family_name?: Maybe<Scalars['String']['output']>;
  /** Non-unique name. */
  given_name: Scalars['String']['output'];
  /** Unique primary key. */
  id: Scalars['ID']['output'];
  /**
   * please switch to using owned_accounts.
   * @deprecated No longer supported
   */
  ownedAccounts: Array<Account>;
  owned_accounts: Array<Account>;
  /** When the account was last updated. */
  updated_at: Scalars['DateTime']['output'];
};

export type ExchangeTempOauthTokenForAuthTokenMutationVariables = Exact<{
  tempToken: Scalars['String']['input'];
}>;


export type ExchangeTempOauthTokenForAuthTokenMutation = { __typename?: 'Mutation', exchangeTempOauthTokenForAuthToken?: { __typename?: 'AuthToken', token?: string | null } | null };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, email: string, created_at: any, email_verified_at?: any | null, family_name?: string | null, given_name: string, updated_at: any, owned_accounts: Array<{ __typename?: 'Account', id: string, account_name?: string | null, credit_amount?: string | null, is_primary?: boolean | null }> } };

export type CreateChatMutationVariables = Exact<{
  owningAccountId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateChatMutation = { __typename?: 'Mutation', createConversation: { __typename?: 'Chat', id: string, created_at: any, name?: string | null, prompt?: string | null, slug: string, updated_at: any } };

export type CreateChatCompletionTextMessageMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  chatId: Scalars['String']['input'];
  modelDefinitionId: Scalars['String']['input'];
  messageText: Scalars['String']['input'];
  parentMessageId: Scalars['String']['input'];
  maxTokens?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateChatCompletionTextMessageMutation = { __typename?: 'Mutation', createChatCompletionTextMessage?: { __typename?: 'ChatMessage', id: string, message: string, source?: Chat_Message_Source | null, updated_at?: any | null, created_at?: any | null, message_transactions: Array<{ __typename?: 'MessageTransaction', id: string, status?: MessageTransactionStatus | null, model_definition?: { __typename?: 'ModelDefinition', official_name: string } | null } | null> } | null };

export type CreateNewChatCompletionTextMessageMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  chatId: Scalars['String']['input'];
  modelDefinitionId: Scalars['String']['input'];
  messageText: Scalars['String']['input'];
  maxTokens?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateNewChatCompletionTextMessageMutation = { __typename?: 'Mutation', createChatCompletionTextMessage?: { __typename?: 'ChatMessage', id: string, message: string, source?: Chat_Message_Source | null, updated_at?: any | null, created_at?: any | null, message_transactions: Array<{ __typename?: 'MessageTransaction', id: string, status?: MessageTransactionStatus | null, model_definition?: { __typename?: 'ModelDefinition', official_name: string } | null } | null> } | null };

export type FavoriteChatMutationVariables = Exact<{
  conversationId: Scalars['String']['input'];
}>;


export type FavoriteChatMutation = { __typename?: 'Mutation', markConversationAsFavorite?: boolean | null };

export type GetConversationQueryVariables = Exact<{
  conversationId: Scalars['String']['input'];
}>;


export type GetConversationQuery = { __typename?: 'Query', getConversation: { __typename?: 'Chat', id: string, created_at: any, name?: string | null, prompt?: string | null, slug: string, updated_at: any, trash_binned_at?: any | null, most_recent_golden_message?: { __typename?: 'ChatMessage', id: string } | null, most_recent_chat_message?: { __typename?: 'ChatMessage', id: string } | null, first_golden_message?: { __typename?: 'ChatMessage', id: string } | null, first_chat_message?: { __typename?: 'ChatMessage', id: string } | null, golden_path_messages: { __typename?: 'ChatMessagePaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number } } } };

export type SubscribeToChatMessageUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeToChatMessageUpdatedSubscription = { __typename?: 'Subscription', chatMessageUpdated?: { __typename?: 'ChatMessage', id: string, message: string, created_at?: any | null, updated_at?: any | null, source?: Chat_Message_Source | null, message_transactions: Array<{ __typename?: 'MessageTransaction', id: string, status?: MessageTransactionStatus | null, model_definition?: { __typename?: 'ModelDefinition', official_name: string } | null } | null>, chat: { __typename?: 'Chat', id: string } } | null };

export type TraverseDownMessageTreeQueryVariables = Exact<{
  count: Scalars['Int']['input'];
  messageId: Scalars['String']['input'];
}>;


export type TraverseDownMessageTreeQuery = { __typename?: 'Query', traverseDownMessageTree: Array<{ __typename?: 'ChatMessage', created_at?: any | null, id: string, message: string, updated_at?: any | null } | null> };

export type TraverseUpMessageTreeQueryVariables = Exact<{
  count: Scalars['Int']['input'];
  messageId: Scalars['String']['input'];
}>;


export type TraverseUpMessageTreeQuery = { __typename?: 'Query', traverseUpMessageTree: Array<{ __typename?: 'ChatMessage', created_at?: any | null, id: string, message: string, updated_at?: any | null } | null> };

export type UnfavoriteChatMutationVariables = Exact<{
  conversationId: Scalars['String']['input'];
}>;


export type UnfavoriteChatMutation = { __typename?: 'Mutation', unfavoriteConversation?: boolean | null };

export type CreateIntentToPurchaseCreditsMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
  creditProductId: Scalars['String']['input'];
}>;


export type CreateIntentToPurchaseCreditsMutation = { __typename?: 'Mutation', createIntentToPurchaseCredits: { __typename?: 'PaymentIntent', amount_received_cents?: number | null, amount_received_formatted?: string | null, charged_amount_cents: number, charged_amount_formatted: string, client_secret: string, id: string, created_at: any, status?: PaymentIntentStatusEnum | null, stripe_id: string } };

export type CreateSetupIntentForPaymentMethodMutationVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;


export type CreateSetupIntentForPaymentMethodMutation = { __typename?: 'Mutation', createSetupIntentForPaymentMethod: { __typename?: 'StripeSetupIntent', client_secret: string, id: string, status: StripeSetupIntentStatusEnum } };

export type GetActiveModelDefinitionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveModelDefinitionsQuery = { __typename?: 'Query', getActiveModelDefinitions: { __typename?: 'ModelDefinitionPaginator', data: Array<{ __typename?: 'ModelDefinition', id?: string | null, official_name: string, public_name: string, uploadable_file_types: Array<string | null> }> } };

export type GetChatMessageQueryVariables = Exact<{
  chatMessageId: Scalars['String']['input'];
}>;


export type GetChatMessageQuery = { __typename?: 'Query', getChatMessage: { __typename?: 'ChatMessage', id: string, message: string, created_at?: any | null, updated_at?: any | null, resent_message_parent?: { __typename?: 'ChatMessage', id: string } | null, parent_message?: { __typename?: 'ChatMessage', id: string } | null } };

export type GetConversationsForAccountQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetConversationsForAccountQuery = { __typename?: 'Query', getAccount?: { __typename?: 'Account', chats: { __typename?: 'ChatPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number }, data: Array<{ __typename?: 'Chat', id: string, slug: string, name?: string | null, prompt?: string | null, updated_at: any, created_at: any, trash_binned_at?: any | null, first_chat_message?: { __typename?: 'ChatMessage', id: string } | null, first_golden_message?: { __typename?: 'ChatMessage', id: string } | null, most_recent_chat_message?: { __typename?: 'ChatMessage', id: string } | null, most_recent_golden_message?: { __typename?: 'ChatMessage', id: string } | null, golden_path_messages: { __typename?: 'ChatMessagePaginator', paginatorInfo: { __typename?: 'PaginatorInfo', total: number } } }> } } | null };

export type GetCreditProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreditProductsQuery = { __typename?: 'Query', getCreditProducts: Array<{ __typename?: 'CreditProduct', credit_amount?: string | null, discount_price_cents?: number | null, discount_price_cents_formatted?: string | null, id: string, is_active?: boolean | null, name?: string | null, regular_price_formatted: string, regular_price_cents: number, subscriber_price_cents?: number | null, subscriber_price_formatted?: string | null, subscription_only?: boolean | null } | null> };

export type GetGoldenPathMessagesQueryVariables = Exact<{
  idOrSlug: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetGoldenPathMessagesQuery = { __typename?: 'Query', getConversation: { __typename?: 'Chat', id: string, slug: string, name?: string | null, prompt?: string | null, updated_at: any, created_at: any, first_chat_message?: { __typename?: 'ChatMessage', id: string } | null, first_golden_message?: { __typename?: 'ChatMessage', id: string } | null, most_recent_chat_message?: { __typename?: 'ChatMessage', id: string } | null, most_recent_golden_message?: { __typename?: 'ChatMessage', id: string } | null, golden_path_messages: { __typename?: 'ChatMessagePaginator', data: Array<{ __typename?: 'ChatMessage', id: string, created_at?: any | null, message: string, updated_at?: any | null, source?: Chat_Message_Source | null, message_transactions: Array<{ __typename?: 'MessageTransaction', id: string, status?: MessageTransactionStatus | null } | null> }>, paginatorInfo: { __typename?: 'PaginatorInfo', total: number, perPage: number, lastPage: number, lastItem?: number | null, hasMorePages: boolean, firstItem?: number | null, currentPage: number, count: number } } } };

export type GetPaymentMethodsForAccountQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
}>;


export type GetPaymentMethodsForAccountQuery = { __typename?: 'Query', getPaymentMethodsForAccount: Array<{ __typename?: 'PaymentMethod', created_at: any, id: string, type: string, card?: { __typename?: 'PaymentMethodCard', brand?: string | null, exp_year?: string | null, exp_month?: string | null, last4?: string | null } | null } | null> };

export type GetTopLevelFoldersByAccountIdQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;


export type GetTopLevelFoldersByAccountIdQuery = { __typename?: 'Query', getAccount?: { __typename?: 'Account', id: string, root_folders: { __typename?: 'FolderPaginator', data: Array<{ __typename?: 'Folder', id: string, name: string, created_at: any, creating_user: { __typename?: 'User', id: string } }>, paginatorInfo: { __typename?: 'PaginatorInfo', total: number, perPage: number, currentPage: number } } } | null };

export type SendChatToTrashBinMutationVariables = Exact<{
  chatId: Scalars['String']['input'];
}>;


export type SendChatToTrashBinMutation = { __typename?: 'Mutation', sendChatToTrashBin?: { __typename?: 'Chat', id: string, trash_binned_at?: any | null } | null };

export type SubscribeToAccountUpdatedSubscriptionVariables = Exact<{
  accountId: Scalars['ID']['input'];
}>;


export type SubscribeToAccountUpdatedSubscription = { __typename?: 'Subscription', accountUpdated?: { __typename?: 'Account', id: string, credit_amount?: string | null, account_name?: string | null, is_primary?: boolean | null } | null };

export type UpdateUserMutationVariables = Exact<{
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, given_name: string, family_name?: string | null, email: string, email_verified_at?: any | null, created_at: any, updated_at: any } | null };


export const ExchangeTempOauthTokenForAuthTokenDocument = `
    mutation exchangeTempOauthTokenForAuthToken($tempToken: String!) {
  exchangeTempOauthTokenForAuthToken(token: $tempToken) {
    token
  }
}
    `;
export const WhoAmIDocument = `
    query whoAmI {
  me {
    id
    email
    created_at
    email_verified_at
    family_name
    given_name
    updated_at
    owned_accounts {
      id
      account_name
      credit_amount
      is_primary
    }
  }
}
    `;
export const CreateChatDocument = `
    mutation createChat($owningAccountId: String!, $name: String, $prompt: String) {
  createConversation(
    owning_account_id: $owningAccountId
    input: {name: $name, prompt: $prompt}
  ) {
    id
    created_at
    name
    prompt
    slug
    updated_at
  }
}
    `;
export const CreateChatCompletionTextMessageDocument = `
    mutation createChatCompletionTextMessage($accountId: String!, $chatId: String!, $modelDefinitionId: String!, $messageText: String!, $parentMessageId: String!, $maxTokens: Int) {
  createChatCompletionTextMessage(
    account_id: $accountId
    chat_id: $chatId
    model_definition_id: $modelDefinitionId
    input: {chat_message_input: {message: $messageText, parent_message_id: $parentMessageId}, chat_options: {max_tokens: $maxTokens}}
  ) {
    id
    message
    source
    updated_at
    created_at
    message_transactions {
      id
      status
      model_definition {
        official_name
      }
    }
  }
}
    `;
export const CreateNewChatCompletionTextMessageDocument = `
    mutation createNewChatCompletionTextMessage($accountId: String!, $chatId: String!, $modelDefinitionId: String!, $messageText: String!, $maxTokens: Int) {
  createChatCompletionTextMessage(
    account_id: $accountId
    chat_id: $chatId
    model_definition_id: $modelDefinitionId
    input: {chat_message_input: {message: $messageText}, chat_options: {max_tokens: $maxTokens}}
  ) {
    id
    message
    source
    updated_at
    created_at
    message_transactions {
      id
      status
      model_definition {
        official_name
      }
    }
  }
}
    `;
export const FavoriteChatDocument = `
    mutation favoriteChat($conversationId: String!) {
  markConversationAsFavorite(conversation_id: $conversationId)
}
    `;
export const GetConversationDocument = `
    query getConversation($conversationId: String!) {
  getConversation(conversation_id: $conversationId) {
    id
    created_at
    name
    prompt
    slug
    updated_at
    trash_binned_at
    most_recent_golden_message {
      id
    }
    most_recent_chat_message {
      id
    }
    first_golden_message {
      id
    }
    first_chat_message {
      id
    }
    golden_path_messages(first: 0, page: 0) {
      paginatorInfo {
        total
      }
    }
  }
}
    `;
export const SubscribeToChatMessageUpdatedDocument = `
    subscription subscribeToChatMessageUpdated {
  chatMessageUpdated {
    id
    message
    created_at
    updated_at
    source
    message_transactions {
      id
      status
      model_definition {
        official_name
      }
    }
    chat {
      id
    }
  }
}
    `;
export const TraverseDownMessageTreeDocument = `
    query traverseDownMessageTree($count: Int!, $messageId: String!) {
  traverseDownMessageTree(count: $count, message_id: $messageId) {
    created_at
    id
    message
    updated_at
  }
}
    `;
export const TraverseUpMessageTreeDocument = `
    query traverseUpMessageTree($count: Int!, $messageId: String!) {
  traverseUpMessageTree(count: $count, message_id: $messageId) {
    created_at
    id
    message
    updated_at
  }
}
    `;
export const UnfavoriteChatDocument = `
    mutation unfavoriteChat($conversationId: String!) {
  unfavoriteConversation(conversation_id: $conversationId)
}
    `;
export const CreateIntentToPurchaseCreditsDocument = `
    mutation createIntentToPurchaseCredits($accountId: String!, $creditProductId: String!) {
  createIntentToPurchaseCredits(
    input: {account_id: $accountId, credit_product_id: $creditProductId, unit_quantity: 1}
  ) {
    amount_received_cents
    amount_received_formatted
    charged_amount_cents
    charged_amount_formatted
    client_secret
    id
    created_at
    status
    stripe_id
  }
}
    `;
export const CreateSetupIntentForPaymentMethodDocument = `
    mutation createSetupIntentForPaymentMethod($accountId: String!) {
  createSetupIntentForPaymentMethod(input: {account_id: $accountId}) {
    client_secret
    id
    status
  }
}
    `;
export const GetActiveModelDefinitionsDocument = `
    query getActiveModelDefinitions {
  getActiveModelDefinitions(first: 10, page: 1) {
    data {
      id
      official_name
      public_name
      uploadable_file_types
    }
  }
}
    `;
export const GetChatMessageDocument = `
    query getChatMessage($chatMessageId: String!) {
  getChatMessage(chat_message_id: $chatMessageId) {
    id
    message
    created_at
    updated_at
    resent_message_parent {
      id
    }
    parent_message {
      id
    }
  }
}
    `;
export const GetConversationsForAccountDocument = `
    query getConversationsForAccount($accountId: String!, $first: Int!, $page: Int!) {
  getAccount(account_id: $accountId) {
    chats(first: $first, page: $page) {
      paginatorInfo {
        total
      }
      data {
        id
        slug
        name
        prompt
        updated_at
        created_at
        first_chat_message {
          id
        }
        first_golden_message {
          id
        }
        most_recent_chat_message {
          id
        }
        most_recent_golden_message {
          id
        }
        golden_path_messages(first: 0, page: 0) {
          paginatorInfo {
            total
          }
        }
        trash_binned_at
      }
    }
  }
}
    `;
export const GetCreditProductsDocument = `
    query getCreditProducts {
  getCreditProducts {
    credit_amount
    discount_price_cents
    discount_price_cents_formatted
    id
    is_active
    name
    regular_price_formatted
    regular_price_cents
    subscriber_price_cents
    subscriber_price_formatted
    subscription_only
  }
}
    `;
export const GetGoldenPathMessagesDocument = `
    query getGoldenPathMessages($idOrSlug: String!, $first: Int!, $page: Int!) {
  getConversation(conversation_id: $idOrSlug) {
    id
    slug
    name
    prompt
    updated_at
    created_at
    first_chat_message {
      id
    }
    first_golden_message {
      id
    }
    most_recent_chat_message {
      id
    }
    most_recent_golden_message {
      id
    }
    golden_path_messages(first: $first, page: $page) {
      data {
        id
        created_at
        message
        updated_at
        source
        message_transactions {
          id
          status
        }
      }
      paginatorInfo {
        total
        perPage
        lastPage
        lastItem
        hasMorePages
        firstItem
        currentPage
        count
      }
    }
  }
}
    `;
export const GetPaymentMethodsForAccountDocument = `
    query getPaymentMethodsForAccount($accountId: String!) {
  getPaymentMethodsForAccount(account_id: $accountId) {
    card {
      brand
      exp_year
      exp_month
      last4
    }
    created_at
    id
    type
  }
}
    `;
export const GetTopLevelFoldersByAccountIdDocument = `
    query getTopLevelFoldersByAccountId($accountId: String!, $first: Int!, $page: Int!) {
  getAccount(account_id: $accountId) {
    id
    root_folders(first: $first, page: $page) {
      data {
        id
        name
        created_at
        creating_user {
          id
        }
      }
      paginatorInfo {
        total
        perPage
        currentPage
      }
    }
  }
}
    `;
export const SendChatToTrashBinDocument = `
    mutation sendChatToTrashBin($chatId: String!) {
  sendChatToTrashBin(chat_id: $chatId) {
    id
    trash_binned_at
  }
}
    `;
export const SubscribeToAccountUpdatedDocument = `
    subscription subscribeToAccountUpdated($accountId: ID!) {
  accountUpdated(id: $accountId) {
    id
    credit_amount
    account_name
    is_primary
  }
}
    `;
export const UpdateUserDocument = `
    mutation updateUser($familyName: String, $givenName: String) {
  updateUser(input: {family_name: $familyName, given_name: $givenName}) {
    id
    given_name
    family_name
    email
    email_verified_at
    created_at
    updated_at
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: !!import.meta.hot,
  endpoints: (build) => ({
    exchangeTempOauthTokenForAuthToken: build.mutation<ExchangeTempOauthTokenForAuthTokenMutation, ExchangeTempOauthTokenForAuthTokenMutationVariables>({
      query: (variables) => ({ document: ExchangeTempOauthTokenForAuthTokenDocument, variables })
    }),
    whoAmI: build.query<WhoAmIQuery, WhoAmIQueryVariables | void>({
      query: (variables) => ({ document: WhoAmIDocument, variables })
    }),
    createChat: build.mutation<CreateChatMutation, CreateChatMutationVariables>({
      query: (variables) => ({ document: CreateChatDocument, variables })
    }),
    createChatCompletionTextMessage: build.mutation<CreateChatCompletionTextMessageMutation, CreateChatCompletionTextMessageMutationVariables>({
      query: (variables) => ({ document: CreateChatCompletionTextMessageDocument, variables })
    }),
    createNewChatCompletionTextMessage: build.mutation<CreateNewChatCompletionTextMessageMutation, CreateNewChatCompletionTextMessageMutationVariables>({
      query: (variables) => ({ document: CreateNewChatCompletionTextMessageDocument, variables })
    }),
    favoriteChat: build.mutation<FavoriteChatMutation, FavoriteChatMutationVariables>({
      query: (variables) => ({ document: FavoriteChatDocument, variables })
    }),
    getConversation: build.query<GetConversationQuery, GetConversationQueryVariables>({
      query: (variables) => ({ document: GetConversationDocument, variables })
    }),
    traverseDownMessageTree: build.query<TraverseDownMessageTreeQuery, TraverseDownMessageTreeQueryVariables>({
      query: (variables) => ({ document: TraverseDownMessageTreeDocument, variables })
    }),
    traverseUpMessageTree: build.query<TraverseUpMessageTreeQuery, TraverseUpMessageTreeQueryVariables>({
      query: (variables) => ({ document: TraverseUpMessageTreeDocument, variables })
    }),
    unfavoriteChat: build.mutation<UnfavoriteChatMutation, UnfavoriteChatMutationVariables>({
      query: (variables) => ({ document: UnfavoriteChatDocument, variables })
    }),
    createIntentToPurchaseCredits: build.mutation<CreateIntentToPurchaseCreditsMutation, CreateIntentToPurchaseCreditsMutationVariables>({
      query: (variables) => ({ document: CreateIntentToPurchaseCreditsDocument, variables })
    }),
    createSetupIntentForPaymentMethod: build.mutation<CreateSetupIntentForPaymentMethodMutation, CreateSetupIntentForPaymentMethodMutationVariables>({
      query: (variables) => ({ document: CreateSetupIntentForPaymentMethodDocument, variables })
    }),
    getActiveModelDefinitions: build.query<GetActiveModelDefinitionsQuery, GetActiveModelDefinitionsQueryVariables | void>({
      query: (variables) => ({ document: GetActiveModelDefinitionsDocument, variables })
    }),
    getChatMessage: build.query<GetChatMessageQuery, GetChatMessageQueryVariables>({
      query: (variables) => ({ document: GetChatMessageDocument, variables })
    }),
    getConversationsForAccount: build.query<GetConversationsForAccountQuery, GetConversationsForAccountQueryVariables>({
      query: (variables) => ({ document: GetConversationsForAccountDocument, variables })
    }),
    getCreditProducts: build.query<GetCreditProductsQuery, GetCreditProductsQueryVariables | void>({
      query: (variables) => ({ document: GetCreditProductsDocument, variables })
    }),
    getGoldenPathMessages: build.query<GetGoldenPathMessagesQuery, GetGoldenPathMessagesQueryVariables>({
      query: (variables) => ({ document: GetGoldenPathMessagesDocument, variables })
    }),
    getPaymentMethodsForAccount: build.query<GetPaymentMethodsForAccountQuery, GetPaymentMethodsForAccountQueryVariables>({
      query: (variables) => ({ document: GetPaymentMethodsForAccountDocument, variables })
    }),
    getTopLevelFoldersByAccountId: build.query<GetTopLevelFoldersByAccountIdQuery, GetTopLevelFoldersByAccountIdQueryVariables>({
      query: (variables) => ({ document: GetTopLevelFoldersByAccountIdDocument, variables })
    }),
    sendChatToTrashBin: build.mutation<SendChatToTrashBinMutation, SendChatToTrashBinMutationVariables>({
      query: (variables) => ({ document: SendChatToTrashBinDocument, variables })
    }),
    updateUser: build.mutation<UpdateUserMutation, UpdateUserMutationVariables | void>({
      query: (variables) => ({ document: UpdateUserDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useExchangeTempOauthTokenForAuthTokenMutation, useWhoAmIQuery, useLazyWhoAmIQuery, useCreateChatMutation, useCreateChatCompletionTextMessageMutation, useCreateNewChatCompletionTextMessageMutation, useFavoriteChatMutation, useGetConversationQuery, useLazyGetConversationQuery, useTraverseDownMessageTreeQuery, useLazyTraverseDownMessageTreeQuery, useTraverseUpMessageTreeQuery, useLazyTraverseUpMessageTreeQuery, useUnfavoriteChatMutation, useCreateIntentToPurchaseCreditsMutation, useCreateSetupIntentForPaymentMethodMutation, useGetActiveModelDefinitionsQuery, useLazyGetActiveModelDefinitionsQuery, useGetChatMessageQuery, useLazyGetChatMessageQuery, useGetConversationsForAccountQuery, useLazyGetConversationsForAccountQuery, useGetCreditProductsQuery, useLazyGetCreditProductsQuery, useGetGoldenPathMessagesQuery, useLazyGetGoldenPathMessagesQuery, useGetPaymentMethodsForAccountQuery, useLazyGetPaymentMethodsForAccountQuery, useGetTopLevelFoldersByAccountIdQuery, useLazyGetTopLevelFoldersByAccountIdQuery, useSendChatToTrashBinMutation, useUpdateUserMutation } = injectedRtkApi;

