import { fetchWithRetry } from "~/utils/fetchWithRetry";
import { WebSocketAppContext } from "../WebSocketAppSingleton";
import { WebSocketConnection } from "../WebSocketConnection";
import { WebSocketSubscription } from "../WebSocketSubscription";
import { store } from "~/redux/store";
import { SubscribeToChatMessageUpdatedDocument } from "~/redux/api.generated";
import { env } from "~/env";
import { WEBSOCKET__CHAT_MESSAGE_UPDATED } from "../WebSocketConstants";
import { z } from "zod";
import _ from "lodash";

export class ChatMessageUpdatedSubscription extends WebSocketSubscription<WebSocketAppContext> {
  channelName: string | null = null;
  authKey: string | null = null;
  wscId: string | null = null;

  public async subscribe(wsc: WebSocketConnection<WebSocketAppContext>) {
    console.log("ChatMessageUpdatedSubscription.subscribe");
    console.log("this.wscId", this.wscId);
    console.log("wsc.id", wsc.id);
    if (this.wscId === wsc.id) return;

    console.log("uh");
    this.status = "initializing";
    this.wscId = wsc.id;

    await this.initialize(wsc);

    wsc.send(
      JSON.stringify({
        event: "pusher:subscribe",
        data: {
          auth: this.authKey,
          channel: this.channelName,
        },
      }),
    );
    this.status = "subscribed";
  }

  public async unsubscribe(wsc: WebSocketConnection<WebSocketAppContext>) {
    void wsc;
    this.status = "unsubscribed";
  }

  protected async initialize(wsc: WebSocketConnection<WebSocketAppContext>) {
    // Fetch the channel name for the WebSocket connection
    const socketId = wsc.context.socketId;

    // saving this.channelName and this.authKey
    const channelName = await this.fetchChannelName();
    await this.fetchAuthKey(channelName, socketId);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(rawEvent: any) {
    // REASON FOR ANY: this comes from the WebSocket API and we don't know
    //                 the structure of all the possible messages.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedEvent = JSON.parse(rawEvent.data) as {
      event: string;
      [x: string]: any;
    };
    // console.log("InitializationSubscription received message", event);
    switch (parsedEvent.event) {
      case "pusher_internal:subscription_succeeded": {
        console.log("ChatMessageUpdatedSubscription: subscription succeeded");
        break;
      }
      case "lighthouse-subscription": {
        const rawData = parsedEvent.data;
        const parsedData = JSON.parse(rawData);

        // const safeParseResult =
        //   this.chatMessageUpdatedValidator.safeParse(parsedData);

        // if (safeParseResult.success === false) return;
        const payload = parsedData.result.data.chatMessageUpdated;
        if (!payload) return;

        store.dispatch({
          type: WEBSOCKET__CHAT_MESSAGE_UPDATED,
          payload,
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  //============================================================================
  // PRIVATE METHODS
  //============================================================================
  //   {
  //     "more": true,
  //     "result": {
  //         "data": {
  //             "chatMessageUpdated": {
  //                 "id": "1ef0bda7-0adc-6ac2-9f41-1d9ab45e02c6",
  //                 "message": "ok, I have said \"ok.\" Is there anything else you need help with?\n\nIf you find this conversation to be repetitive or unhelpful, please let me know so I can better understand how to assist you. I'm here to help!",
  //                 "created_at": "2024-05-06T18:57:09+00:00",
  //                 "updated_at": "2024-05-06T18:57:17+00:00",
  //                 "source": "BOT",
  //                 "message_transactions": [
  //                     {
  //                         "id": "1ef0bda6-b8d3-6e88-ae8b-0fdd4fbf82db",
  //                         "status": "DONE",
  //                         "model_definition": {
  //                             "official_name": "mistral-small-latest"
  //                         }
  //                     }
  //                 ],
  //                 "chat": {
  //                     "id": "1ef09986-127e-68b0-980d-5b176401db2f"
  //                 }
  //             }
  //         }
  //     }
  // }

  chatMessageUpdatedValidator = z.object({
    more: z.boolean(),
    result: z.object({
      data: z.object({
        chatMessageUpdated: z.object({
          id: z.string(),
          message: z.string(),
          created_at: z.string(),
          updated_at: z.string(),
          source: z.string(),
          message_transactions: z.array(
            z.object({
              id: z.string(),
              status: z.string(),
              model_definition: z.object({
                official_name: z.string(),
              }),
            }),
          ),
          chat: z.object({
            id: z.string(),
          }),
        }),
      }),
    }),
  });

  /**
   * Fetches the channel name for the WebSocket subscription.
   * @returns {Promise<string>} The channel name.
   */
  private async fetchChannelName(): Promise<string> {
    // TODO: see if this can be cached
    // if (this.channelName) return this.channelName;

    /** Expected structure of a response from fetching a channel name. */
    interface FetchChannelNameResponse {
      data: { chatMessageUpdated: null };
      extensions: {
        lighthouse_subscriptions: { channel: `private-lighthouse-${string}` };
      };
    }
    const response: FetchChannelNameResponse = await fetchWithRetry({
      url: `${env.VITE_API_URL}/graphql`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${store.getState().auth.token}`,
        },
        body: JSON.stringify({
          query: SubscribeToChatMessageUpdatedDocument,
        }),
      },
    });
    const channelName = response?.extensions?.lighthouse_subscriptions?.channel;

    if (_.isNil(channelName)) {
      return this.fetchChannelName();
    }

    // Return the channel name from the response
    this.channelName = channelName;
    return this.channelName;
  }

  /**
   * Fetches the authorization key for subscribing to a channel.
   * @param {string} channelName The name of the channel.
   * @param {string} socketId The socket ID.
   * @returns {Promise<string>} The authorization key.
   */
  private async fetchAuthKey(
    channelName: string,
    socketId: string,
  ): Promise<string> {
    // TODO: see if this can be cached
    // if (this.authKey) return this.authKey;

    const authResult = await fetchWithRetry<{
      auth: string;
    }>({
      url: `${env.VITE_API_URL}/graphql/subscriptions/auth`,
      options: {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          channel_name: channelName,
          socket_id: socketId,
        }),
      },
    });

    this.authKey = authResult.auth;
    return this.authKey;
  }
}
