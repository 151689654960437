/**
 * @overview Manages dynamic, bidirectional relationships between entities, enabling seamless representation
 * and manipulation of many-to-many relationships within a Redux state. Utilizing Redux Toolkit for state management,
 * this implementation allows for direct and reverse relationship management, ensuring consistency and simplicity
 * in handling complex relationship patterns. Designed for flexibility, it supports additional behaviors through
 * extraReducers, enhancing Redux applications with complex relational data structures.
 */

import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import {
  addBidirectionalLink,
  removeBidirectionalLink,
  clearAllLinksForEntity,
} from "./ropeUtils";

/** Utility type to make other types clearer */
export type ID = string;

// Defines the state structure, mapping entity IDs to arrays of related entity IDs for bidirectional relationships.
export interface RopesState {
  [id: ID]: ID[];
}

export function createRope<N extends string>(
  name: N,
  {
    extraReducers,
  }: {
    extraReducers?: (builder: ActionReducerMapBuilder<RopesState>) => void;
  } = {},
) {
  const initialState: RopesState = {};

  return createSlice({
    name: `rope_${name}`,
    initialState,
    reducers: {
      /**
       * Adds a bidirectional link between entities. Utilizes utility functions for direct and reverse
       * relationship management, maintaining symmetry and supporting many-to-many relationships.
       */
      addLink(state, action: PayloadAction<{ sourceId: ID; targetId: ID }>) {
        const { sourceId, targetId } = action.payload;
        addBidirectionalLink(state, sourceId, targetId);
      },
      /**
       * Removes a bidirectional link between entities. Ensures the removal of links in both directions,
       * keeping the state consistent and clean.
       */
      removeLink(state, action: PayloadAction<{ sourceId: ID; targetId: ID }>) {
        const { sourceId, targetId } = action.payload;
        removeBidirectionalLink(state, sourceId, targetId);
      },
      /**
       * Clears all links for a given entity ID. This action cleans up any references to the specified entity,
       * ensuring no dangling links remain.
       */
      clearLinks(state, action: PayloadAction<{ id: ID }>) {
        const { id } = action.payload;
        clearAllLinksForEntity(state, id);
      },
      clearState() {
        return initialState;
      },
    },
    extraReducers,
  });
}
